import { v4 as uuid } from 'uuid';

export default class {
  constructor(props = {}) {}

  toObject(extend = {}) {
    return { ...this, ...extend };
  }

  toStringify() {
    return JSON.stringify({ ...this });
  }
}
