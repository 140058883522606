import React, { Fragment, useState } from 'react';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  Box,
  Link,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  List,
  ListItem,
  Collapse,
} from '@material-ui/core';
import { UserMenu } from './components';
import palette from 'themes/palette';

//Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import MenuIcon from '@material-ui/icons/Menu';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

//Services
import { useAuth } from 'services/AuthService';

const useStyles = makeStyles((theme) => ({
  root: {
    // boxShadow: 'none',
    backgroundColor: '#f1f2ef',
    backgroundImage: 'url(/images/background_title.jpg)',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
    color: theme.palette.primary.main,
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      height: '35px',
    },
  },
  linksContainer: {
    display: 'flex',
    marginRight: '20px',
    alignItems: 'center',
    borderRight: '1px solid #bbbbbb',
    paddingRight: '28px',
  },
  //Navbar links
  linkBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '20px',
    color: palette.highway.main,
  },
  linkText: {
    color: palette.highway.dark,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '13px',
    textTransform: 'uppercase',
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px',
    borderBottom: '2px solid transparent',
    cursor: 'pointer',
    '&:hover': {
      color: palette.river.light,
      borderBottom: '2px solid ' + palette.river.light,
    },
  },
  selected: {
    fontWeight: 600,
    color: palette.river.mediumlight,
    borderBottom: '2px solid ' + palette.river.mediumlight,
  },
  // Dropdown menu links
  menuLink: {
    display: 'flex',
    alignItems: 'center',
    color: palette.highway.main,
    textDecoration: 'none',
    width: '100%',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  iconBottom: {
    marginBottom: '4px',
  },
  iconRight: {
    marginRight: '10px',
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;
  const { isAuthenticated } = useAuth();
  const classes = useStyles();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [completedMenu, setCompletedMenu] = useState(null);
  const [openCompleted, setOpenCompleted] = useState(false);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleOpenCompletedClick = () => {
    setOpenCompleted(!openCompleted);
  };
  //Completed Menu
  const handleCompletedMenuClick = (event) => {
    setCompletedMenu(event.currentTarget);
  };
  const handleCompletedMenuClose = () => {
    setCompletedMenu(null);
  };

  return (
    <Box className="dont-print">
      <AppBar {...rest} className={clsx(classes.root, className)}>
        <Toolbar>
          <RouterLink to="/payment">
            <img
              alt="Logo"
              src="/images/logos/nwcu-logo.png"
              className={classes.logo}
            />
          </RouterLink>
          <div className={classes.flexGrow} />

          {/* We only want to show links if user is logged in */}
          {isAuthenticated ? (
            <Fragment>
              <Hidden xsDown>
                <Box className={classes.linksContainer}>
                  <NavLink
                    to="/payment"
                    className={clsx(classes.linkText, classes.linkBox)}
                    activeClassName={classes.selected}
                  >
                    <MonetizationOnIcon style={{ marginBottom: '4px' }} />
                    <Box>Payment</Box>
                  </NavLink>
                  <NavLink
                    to="/glonly"
                    className={clsx(classes.linkText, classes.linkBox)}
                    activeClassName={classes.selected}
                  >
                    <CreditCardIcon style={{ marginBottom: '4px' }} />
                    <Box>GL Payment</Box>
                  </NavLink>

                  <Box
                    className={clsx(classes.linkText, classes.linkBox)}
                    onClick={(event) => {
                      handleCompletedMenuClick(event);
                    }}
                  >
                    <CheckCircleOutlineIcon className={classes.iconBottom} />
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      Completed <ArrowDropDownIcon fontSize="small" />
                    </Box>
                  </Box>
                  <Menu
                    id="completed-menu"
                    anchorEl={completedMenu}
                    keepMounted
                    open={Boolean(completedMenu)}
                    onClose={handleCompletedMenuClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  >
                    <MenuItem onClick={handleMenuClose}>
                      <NavLink
                        to="/publicpayments"
                        className={clsx(classes.menuLink)}
                      >
                        <CheckCircleIcon className={classes.iconRight} />
                        <Box>Public Completed</Box>
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                      <NavLink
                        to="/completedpayments"
                        className={clsx(classes.menuLink)}
                      >
                        <AssignmentTurnedInIcon className={classes.iconRight} />
                        <Box>Employee Completed</Box>
                      </NavLink>
                    </MenuItem>
                  </Menu>
                </Box>
              </Hidden>

              <Hidden smUp>
                <IconButton color="inherit" onClick={handleMenuClick}>
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={menuAnchorEl}
                  keepMounted
                  open={Boolean(menuAnchorEl)}
                  onClose={handleMenuClose}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <MenuItem onClick={handleMenuClose}>
                    <RouterLink to="/payment" className={classes.menuLink}>
                      <MonetizationOnIcon style={{ marginRight: '6px' }} />
                      Make a Payment
                    </RouterLink>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <RouterLink to="/glonly" className={classes.menuLink}>
                      <CreditCardIcon style={{ marginRight: '6px' }} />
                      Make a GL Payment
                    </RouterLink>
                  </MenuItem>

                  <ListItem button onClick={handleOpenCompletedClick}>
                    <Box className={classes.menuLink} style={{ width: '100%' }}>
                      <CheckCircleOutlineIcon className={classes.iconRight} />
                      Completed
                      <Box
                        style={{ flexGrow: 1 }}
                        className={classes.iconRight}
                      />
                      {openCompleted ? <ExpandLess /> : <ExpandMore />}
                    </Box>
                  </ListItem>
                  <Collapse in={openCompleted} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem button className={classes.nested}>
                        <NavLink
                          to="/publicpayments"
                          className={clsx(classes.menuLink)}
                        >
                          <CheckCircleIcon className={classes.iconRight} />
                          <Box>Public Completed</Box>
                        </NavLink>
                      </ListItem>
                      <ListItem button className={classes.nested}>
                        <NavLink
                          to="/completedpayments"
                          className={clsx(classes.menuLink)}
                        >
                          <AssignmentTurnedInIcon
                            className={classes.iconRight}
                          />
                          <Box>Employee Completed</Box>
                        </NavLink>
                      </ListItem>
                    </List>
                  </Collapse>
                </Menu>
              </Hidden>
            </Fragment>
          ) : (
            ''
          )}

          <UserMenu />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
