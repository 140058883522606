// --- Import --- //
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// --- Material Ui --- //
import { default as MuiDialog } from '@material-ui/core/Dialog';
import {
  useMediaQuery,
  DialogTitle,
  DialogActions,
  DialogContent,
  makeStyles,
  Box,
  Button,
  IconButton,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import palette from 'themes/palette';

//Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: '24px 32px',
    [theme.breakpoints.down('xs')]: {
      padding: '24px 16px',
    },
  },
  actionPadding: {
    padding: '12px',
  },
  title: (props) => ({
    fontSize: 24,
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    color:
      props.type === 'success'
        ? palette.forest.main
        : props.type === 'error'
        ? palette.brick.main
        : props.type === 'loading'
        ? palette.highway.main
        : palette.highway.main,
  }),
  titleRoot: {
    padding: '12px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
    },
  },
  alert: {
    fontWeight: 300,
    fontSize: '130%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '110%',
    },
  },
  // scrollbox: {
  //   overflow: 'auto',
  //   background:
  //     'linear-gradient(white 30%, rgba(255,255,255,0)), linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,radial-gradient(50% 0, farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)),radial-gradient(50% 100%,farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%',
  //   background:
  //     ' linear-gradient(white 30%, rgba(255,255,255,0)),linear-gradient(rgba(255,255,255,0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundColor: 'white',
  //   backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
  //   backgroundAttachment: 'local, local, scroll, scroll',
  // },
}));

const CustomPopUpAlert = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  //   const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { title, children, onClose, disableClose, type, ...rest } = props;

  return (
    <MuiDialog
      //   fullScreen={fullScreen}
      maxWidth="sm"
      disableBackdropClick={disableClose}
      disableEscapeKeyDown={disableClose}
      {...rest}
    >
      {title ? (
        <DialogTitle className={classes.titleRoot}>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box className={classes.title}>
              {type === 'success' ? (
                <CheckCircleIcon
                  style={{ marginRight: '10px' }}
                  fontSize="large"
                />
              ) : type === 'error' ? (
                <ErrorIcon style={{ marginRight: '10px' }} fontSize="large" />
              ) : type === 'loading' ? (
                <RotateLeftIcon
                  style={{ marginRight: '10px' }}
                  fontSize="large"
                />
              ) : (
                ''
              )}
              {title}
            </Box>
            {!disableClose ? (
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : (
              ''
            )}
          </Box>
        </DialogTitle>
      ) : (
        ''
      )}
      <DialogContent
        dividers
        className={clsx(classes.padding, classes.scrollbox)}
      >
        <Box className={classes.alert}>{children}</Box>
      </DialogContent>
      {type !== 'loading' ? (
        <DialogActions className={classes.actionPadding}>
          <Button
            onClick={() => {
              onClose();
            }}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      ) : (
        ''
      )}
    </MuiDialog>
  );
};

CustomPopUpAlert.defaultProps = {
  type: 'neutral',
};

export default CustomPopUpAlert;
