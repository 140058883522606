// --- Import --- //
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// --- Material Ui --- //
import { default as MuiDialog } from '@material-ui/core/Dialog';
import {
  useMediaQuery,
  DialogTitle,
  DialogActions,
  DialogContent,
  makeStyles,
  Box,
  IconButton,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import palette from 'themes/palette';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: '24px 32px',
    [theme.breakpoints.down('sm')]: {
      padding: '12px 16px',
    },
  },
  title: {
    fontSize: 24,
    padding: '16px 8px',
    color: palette.forest.dark,
  },
  titleRoot: {
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
    },
  },
  scrollbox: {
    overflow: 'auto',
    background:
      'linear-gradient(white 30%, rgba(255,255,255,0)), linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,radial-gradient(50% 0, farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)),radial-gradient(50% 100%,farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%',
    background:
      ' linear-gradient(white 30%, rgba(255,255,255,0)),linear-gradient(rgba(255,255,255,0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white',
    backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
    backgroundAttachment: 'local, local, scroll, scroll',
  },
}));

const Dialog = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  // const [open, setOpen] = useState(props.open || false);
  // const [fullWidth, setFullWidth] = useState(true);
  // const [fullHeight, setFullHeight] = useState('65vh');
  // const [maxWidth, setMaxWidth] = useState('lg');
  const fullWidth = true;
  const fullHeight = '65vh';
  const maxWidth = 'md';
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const { title, actions, disableClose, children, ...rest } = props;

  return (
    <MuiDialog
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      maxWidth={props.maxWidth ? props.maxWidth : maxWidth}
      disableBackdropClick={disableClose}
      disableEscapeKeyDown={disableClose}
      {...rest}
    >
      {title ? (
        <DialogTitle className={classes.titleRoot}>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box className={classes.title}>{title}</Box>
            {!disableClose ? (
              <IconButton onClick={props.onClose}>
                <CloseIcon />
              </IconButton>
            ) : (
              ''
            )}
          </Box>
        </DialogTitle>
      ) : (
        ''
      )}
      <DialogContent
        dividers
        // style={{ height: fullHeight }}
        className={clsx(classes.padding, classes.scrollbox)}
      >
        {children}
      </DialogContent>
      {actions ? (
        <DialogActions className={classes.padding}>{actions}</DialogActions>
      ) : (
        ''
      )}
    </MuiDialog>
  );
};

Dialog.propTypes = {
  disableClose: PropTypes.bool,
};

export default Dialog;
