const river = {
  50: '#E3EDEE',
  100: '#B9D1D4',
  200: '#8AB2B8',
  300: '#5B939B',
  400: '#387C85',
  500: '#156570',
  600: '#125D68',
  700: '#0F535D',
  800: '#0C4953',
  900: '#063741',
  A100: '#77E5FF',
  A200: '#44DCFF',
  A400: '#11D2FF',
  A700: '#00C8F7'
};

export default river;
