import { BaseClass } from 'utilities';
import { DebitCardModel } from 'modules/DebitCard';
import { BankTransferModel } from 'modules/BankTransfer';
import { v4 as uuid } from 'uuid';
import ErrorModel from 'modules/Error/ErrorModel';

const PaymentFormModel = class extends BaseClass {
  constructor(props = {}) {
    super(props);
    this.sessionId = props.sessionId || uuid();
    // this.firstName = props.firstName || '';
    this.lastName = props.lastName || '';
    // this.lastFiveSSN = props.lastFiveSSN || '';
    this.email = props.email || '';
    this.loanNumber = props.loanNumber || '';
    this.applyFee = props.applyFee === false ? false : true;
    this.feeAmount = props.feeAmount || 10;
    this.paymentAmount = props.paymentAmount || 0;
    this.overrideMaxAmount = props.overrideMaxAmount || false;
    this.overrideBillingAddress = props.overrideBillingAddress || false;
    this.overrideDebitCardCheck = props.overrideDebitCardCheck || false;
    this.overrideAccountNumber = props.overrideAccountNumber || false;
    this.paymentType = props.paymentType || '';
    this.debitCard =
      new DebitCardModel(props.debitCard) || new DebitCardModel();
    this.bankTransfer =
      new BankTransferModel(props.bankTransfer) || new BankTransferModel();
    this.feeAccountNegative = props.feeAccountNegative || 0;
    this.payAccountNegative = props.payAccountNegative || 0;
    this.overrideNegativeBalance = props.overrideNegativeBalance || false;
    this.isOverridden = props.isOverridden || false;
    this.errors = new Array<new ErrorModel(props.ErrorModel)>[] || new Array(new ErrorModel())>[];
  }
};

export default PaymentFormModel;
