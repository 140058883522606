// --- Imports --- //

// --- Yup/Validation Imports --- //
import * as yup from 'yup';
import {
  alpha_num_limited,
  number,
  creditCardExpiration,
} from 'utilities/validation/regex';

import { RequiredAddressSchema } from 'modules/Address';

import moment from 'moment';

const DebitCardSchema = yup.object().shape({
  nameOnCard: yup
    .string()
    .label('Name on Card')
    .matches(alpha_num_limited.regex, alpha_num_limited.message)
    .required(),
  cardNumber: yup
    .string()
    .label('Card Number')
    .matches(number.regex, number.message)
    .min(15)
    .max(16)
    .when('isDebit', {
      is: (isDebit) => !isDebit,
      then: yup.string().min(100, 'Only debit cards are allowed.'),
    })
    .required(),
  securityCode: yup
    .string()
    .label('Security Code')
    .min(3)
    .max(4)
    .when('cardNumber', {
      is: (cardNumber) => cardNumber && cardNumber.length === 15,
      then: yup
        .string()
        .min(4, 'Security Code must be 4 characters for 15 digit Card Number'),
    })
    .when('cardNumber', {
      is: (cardNumber) => cardNumber && cardNumber.length === 16,
      then: yup
        .string()
        .max(3, 'Security Code must be 3 characters for 16 digit Card Number'),
    })
    .matches(number.regex, number.message)
    .required(),
  expirationDate: yup
    .string()
    .label('Expiration Date')
    .typeError('Date format is invalid')
    .matches(creditCardExpiration.regex, creditCardExpiration.message)
    .test(
      'hasExpired',
      "Expiration Date must be after today's date",
      (value) => {
        var month =
          value.slice(0, 2) === '12'
            ? '01'
            : (parseInt(value.slice(0, 2)) + 1).toString();
        var year =
          value.slice(0, 2) === '12'
            ? (parseInt(value.slice(2, 6)) + 1).toString()
            : value.slice(2, 6);
        return moment(`${month}/01/${year}`).isAfter(moment());
      }
    )
    .nullable()
    .required(),
  billingAddress: RequiredAddressSchema,
});

export default DebitCardSchema;
