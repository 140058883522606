/* eslint-disable react-hooks/exhaustive-deps */
// --- Imports --- //
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// --- Material Ui --- //
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';

// // --- Services --- //
// import { useCatalogService } from 'services/CatalogService';

// --- Custom Components --- //
import { Topbar, Footer, TreeFooter } from './components';

// --- Redux --- //
// import { connect } from 'react-redux';
// import {
//   actions as CatalogActions,
//   types as CatalogTypes,
// } from 'store/Catalog';

// const mapStateToProps = (state, props) => {
//   return {
//     ...props,
//     catalogStatus: state.catalog.status,
//   };
// };

// const mapDispatchToProps = (dispatch, props) => {
//   return {
//     ...props,
//     catalogFetching: () =>
//       dispatch(CatalogActions.setStatus(CatalogTypes.CATALOG_STATUS_FETCHING)),
//     catalogSuccess: () =>
//       dispatch(CatalogActions.setStatus(CatalogTypes.CATALOG_STATUS_SUCCESS)),
//     catalogError: () =>
//       dispatch(CatalogActions.setStatus(CatalogTypes.CATALOG_STATUS_ERROR)),
//     setCatalog: (data) => dispatch(CatalogActions.setCatalog(data)),
//   };
// };

// --- Styles --- //
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 88,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 96,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 72,
    },
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    //height: '100%',
  },
  footer: {
    color: 'white',
  },
}));

const Main = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Topbar onSidebarOpen={() => {}} />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

// export default connect(mapStateToProps, mapDispatchToProps)(Main);
export default Main;
