/* eslint-disable no-unused-vars */
// --- Imports --- //
import axios from 'axios';

const AppSettingsService = class {
  // constructor(logService, recaptchaService) {
  constructor() {
    // Axios client initialization
    this.client = axios.create({
      baseURL: `${process.env.REACT_APP_SETTINGS_API_URL}/api/${process.env.REACT_APP_SETTINGS_API_VERSION}/applicationsettings`,
      validateStatus: function () {
        return true;
      },
      headers: { 'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SETTINGS_API_KEY}` }
    });
  }

  async getAppSettings(email) {
    try {
      const { data: response, status } = await this.client.get('', {
        params: {
          application: process.env.REACT_APP_SETTINGS_NAME,
          username: email,
        },
      });
      return response;
    } catch (error) {
      console.log('error', error);
      return {};
    }
  }

  async setAppSettings(appSettings) {
    try {
      const { data: response, status } = await this.client.post(
        '',
        appSettings
      );
      return response;
    } catch (error) {
      console.log('error', error);
      return {};
    }
  }
};

export const useAppSettingsService = (props) => {
  return new AppSettingsService();
};

// export default new AppSettingsService(LogService('AppSettingsService'));
export default new AppSettingsService();
