import React, { useState, Fragment } from 'react';
import palette from 'themes/palette';
import { getInitials } from 'utilities/helpers';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

// --- Material Ui --- //
import {
  makeStyles,
  Typography,
  Avatar,
  Button,
  Menu,
  MenuItem,
  Popover,
  useTheme,
  useMediaQuery,
  IconButton,
  Box,
} from '@material-ui/core';

//Icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { default as ProfileIcon } from '@material-ui/icons/AccountCircle';
import { default as LogoutIcon } from '@material-ui/icons/ExitToApp';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';

// --- Services --- //
import { useAuth } from 'services/AuthService';

const UserMenu = (props) => {
  const {
    user,
    isAuthenticated,
    handleLogin,
    handleLogout,
    appSettings,
  } = useAuth();

  //Popover Section//
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // --- Style --- //
  const useStyle = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      height: 'inherit',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    username: {},
    profilePicture: {
      margin: theme.spacing(0, 1, 0, 1),
      backgroundColor: palette.forest.main,
      border: '4px solid #f1f2ef',
      overflow: 'hidden',
      border: open ? '4px solid #cccccc  ' : '4px solid #f1f2ef',
      cursor: 'pointer',
      fontWeight: 400,
      '&:hover': {
        border: '4px solid #cccccc',
        cursor: 'pointer',
      },
    },

    menu: {
      width: 150,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    menuIcon: {
      margin: theme.spacing(0, 1, 0, 0),
    },

    //Popover
    accountInfoBox: {
      padding: '20px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      borderBottom: '1px solid #e8eaed',
    },
    logoutBox: { padding: '15px', textAlign: 'center' },
    name: {
      fontSize: '18px',
      fontWeight: 500,
      marginTop: '15px',
      color: palette.highway.main,
    },
    email: {
      fontSize: '14px',
      fontWeight: 400,
      color: palette.highway.light,
      marginTop: '5px',
    },
    userMenuRow: {
      textDecoration: 'none',
      padding: '15px 20px',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 500,
      color: palette.highway.main,
      cursor: 'pointer',
      borderBottom: '1px solid #e8eaed',
      '&:hover': {
        backgroundColor: '#f7f8f8',
      },
    },
  }));

  const classes = useStyle();

  // --- Constants --- //
  const menuId = 'user-profile-menu';

  // --- States --- //
  const [desktopAnchorEl, setDesktopAnchorEl] = useState(null);

  // --- Handlers --- //
  const handleMenuOpen = (event) => setDesktopAnchorEl(event.currentTarget);
  const handleMenuClose = () => setDesktopAnchorEl(null);

  // --- Menus --- //
  const renderDesktopMenu = (
    <Menu
      anchorEl={desktopAnchorEl}
      id={menuId}
      keepMounted
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={Boolean(desktopAnchorEl)}
      onClose={handleMenuClose}
      PaperProps={{ className: classes.menu }}
    >
      <MenuItem onClick={handleLogout}>
        <LogoutIcon className={classes.menuIcon} />
        Log Out
      </MenuItem>
    </Menu>
  );

  //Functions for popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const AccountPopover = (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 60,
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box>
        <Box className={classes.accountInfoBox}>
          <AccountCircleIcon color="primary" fontSize="large" />
          <Box className={classes.name}>{user.displayName}</Box>
          <Box className={classes.email}>{user.mail ? user.mail : user.userPrincipalName}</Box>
          <Box className={classes.email}>{appSettings.defaultBranchName}</Box>
        </Box>
        <NavLink to="/branches" className={classes.userMenuRow}>
          <SettingsRoundedIcon
            fontSize="small"
            style={{ marginRight: '10px' }}
          />
          Manage User Settings
        </NavLink>
        <Box className={classes.logoutBox}>
          <Button variant="outlined" size="small" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </Box>
    </Popover>
  );

  if (!isAuthenticated) {
    return (
      <Button variant="outlined" onClick={handleLogin}>
        Login
      </Button>
    );
  }
  return (
    <Fragment>
      {/* <Button
        className={classes.root}
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        // onClick={handleMenuOpen}
        onClick={handleClick}
      > */}
      <Avatar
        alt={user.displayName}
        className={clsx(classes.profilePicture)}
        tabIndex="0"
        onClick={handleClick}
      >
        {getInitials(user.displayName)}
      </Avatar>
      {/* </Button> */}
      {/* {renderDesktopMenu} */}
      {AccountPopover}
    </Fragment>
  );
};

export default UserMenu;
