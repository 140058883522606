import React from 'react';
import './App.css';

//Recaptcha
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

//Theme
import { ThemeProvider } from '@material-ui/styles';
import theme from './themes';

import { ScrollToTop } from 'routes/components';

//Routes
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import Routes from './routes';

// --- Store Imports --- //
import { Provider } from 'react-redux';
import store from 'store';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store';

//Services
import { useCatalogService } from 'services/CatalogService';

//Services
import { AuthProvider } from 'services/AuthService';

const browserHistory = createBrowserHistory();

function App() {
  return (
    <Provider store={store}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      >
        <AppContents />
      </GoogleReCaptchaProvider>
    </Provider>
  );
}

function AppContents() {
  //Hooks
  const dispatch = useDispatch();
  const catalogService = useCatalogService();

  //Get branches from API
  catalogService.getBranches().then((response) => {
    dispatch(actions.setAllBranches(response.data));
  }).catch((error) => { console.log(error) });

  return (
    <ThemeProvider theme={theme}>
      <Router history={browserHistory}>
        <AuthProvider>
          <ScrollToTop />
          <Routes />
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
