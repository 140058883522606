import { BaseClass } from 'utilities';
import { AddressModel } from 'modules/Address';

const DebitCardModel = class extends BaseClass {
  constructor(props = {}) {
    super(props);
    this.nameOnCard = props.nameOnCard || '';
    this.cardNumber = props.cardNumber || '';
    this.securityCode = props.securityCode || '';
    this.expirationDate = props.expirationDate || '';
    this.billingAddress =
      new AddressModel(props.billingAddress) || new AddressModel();
    this.isDebit = props.isDebit === false ? false : true;
  }
};

export default DebitCardModel;
