import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import Axios from 'axios';

//Material UI
import {
  Box,
  Grid,
  CircularProgress,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

//Components
import { TextMaskCustom } from 'components';

//Themes
import palette from 'themes/palette';

//Formik
import { TextField } from 'formik-material-ui';
import { Formik, Form, useFormikContext, useField } from 'formik';

//Modules
import { Address } from 'modules/Address';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginBottom: '15px',
    fontWeight: '500',
    fontSize: '120%',
  },
}));

const DebitCard = (props) => {
  const classes = useStyles();
  var prefix = props.prefix ? `${props.prefix}.` : '';
  const [ignoreDebitValidation, setIgnoreDebitValidation] = useState(false);
  const [debitfield, debitmeta, debithandler] = useField(prefix + 'isDebit');
  const { values, setValues } = useFormikContext();
  useEffect(() => {
    if (values.debitCard.cardNumber && !ignoreDebitValidation) {
      if (
        values.debitCard.cardNumber.length === 15 ||
        values.debitCard.cardNumber.length === 16
      ) {
        Axios.get(
          'https://lookup.binlist.net/' +
            values.debitCard.cardNumber.substring(0, 8)
        )
          .then((response) => {
            try {
              if (response.data.type.toLowerCase() !== 'debit') {
                debithandler.setValue(false);
              }
            } catch (error) {}
          })
          .catch((error) => {});
      } else {
        debithandler.setValue(true);
      }
    } else {
      debithandler.setValue(true);
    }
    // console.log('values', values);
  }, [values.debitCard.cardNumber, ignoreDebitValidation]);

  useEffect(() => {
    // console.log('updating value to pass to api');
    props.setDebitOverride(ignoreDebitValidation);
  }, [ignoreDebitValidation]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Box className={classes.subtitle}>Debit Card Details:</Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <TextField
            name={prefix + 'nameOnCard'}
            label="Name on Card"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          {/* <TextField
            name={prefix + 'cardNumber'}
            label="Card Number"
            fullWidth
            variant="outlined"
          /> */}
          <TextMaskCustom
            name={prefix + 'cardNumber'}
            label="Card Number"
            fullWidth
            variant="outlined"
            maskType="debitCard"
            unmask={true}
            hideField
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={ignoreDebitValidation}
                style={{ marginRight: '10px' }}
                color="primary"
                onChange={() =>
                  setIgnoreDebitValidation(!ignoreDebitValidation)
                }
              />
            }
            label="Override Debit Card Check?"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            name={prefix + 'securityCode'}
            label="Security Code (CVV)"
            fullWidth
            variant="outlined"
            inputProps={{
              maxLength:
                values.debitCard.cardNumber &&
                values.debitCard.cardNumber.length === 16
                  ? 3
                  : 4,
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          {/* <TextField
            name={prefix + 'expirationDate'}
            label="Expiration Date"
            fullWidth
            variant="outlined"
          /> */}
          <TextMaskCustom
            name={prefix + 'expirationDate'}
            label="Expiration Date (mm/yyyy)"
            fullWidth
            variant="outlined"
            maskType="expirationDate"
            unmask={true}
          />
        </Grid>
      </Grid>
      <Address prefix={prefix + 'billingAddress'} />
    </Box>
  );
};

export default DebitCard;
