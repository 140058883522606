import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MaskedInput, { conformToMask } from 'react-text-mask';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, InputAdornment } from '@material-ui/core';

import { useField } from 'formik';
import {
  phoneNumber,
  date,
  debitCard,
  expirationDate,
  americanExpress,
  cvv,
} from './Masks';

const masks = {
  phone: phoneNumber,
  date: date,
  debitCard: debitCard,
  expirationDate: expirationDate,
  cvv: cvv,
};

const TextMaskCustom = (props) => {
  const { name, helperText, maskType, unmask, hideField, ...rest } = props;

  // -- Hooks ---//
  const [field, meta, helpers] = useField(name);
  const [mask, setMask] = useState(masks[maskType]);
  const [displayValue, setDisplayValue] = useState('');
  const [hide, setHide] = useState(hideField);

  const showError = meta.error && meta.touched;

  useEffect(() => {
    if (maskType === 'debitCard') {
      switch (field.value.charAt(0)) {
        //American Express starts with 3 and will have a different looking mask (4-6-5)
        case '3':
          setMask(americanExpress);
          break;
        //Otherwise, normal 4-4-4-4 debit card
        default:
          setMask(debitCard);
          break;
      }
    }
    setDisplayValue(field.value);
  }, [field.value]);

  //If the show/hide button is clicked, this toggles the visibility of the field
  const toggleHide = () => setHide(!hide);

  //On every character typed, we set the newValue.
  //We unmask it only if that field is passed in.
  const handleChange = (event) => {
    const newValue = event.target.value;
    if (unmask) {
      helpers.setValue(unmaskValue(newValue));
    } else {
      helpers.setValue(newValue);
    }
    setDisplayValue(newValue);
  };

  // This makes it so it doesnt show an error until the box has been left
  const handleBlur = () => {
    helpers.setTouched(true);
  };

  return (
    <TextField
      error={showError}
      value={displayValue}
      name={name}
      type={hide ? 'password' : 'text'}
      helperText={showError ? meta.error : helperText}
      onChange={handleChange}
      onBlur={(event) => {
        handleChange(event);
        handleBlur();
      }}
      InputProps={{
        inputComponent: CustomMaskInput,
        inputProps: {
          mask: !hide ? mask : removeNonDigitsFromMask(mask),
          guide: false,
        },
        endAdornment: hideField ? (
          <InputAdornment position="end">
            <Button size="small" onClick={toggleHide}>
              {hide ? 'Show' : 'Hide'}
            </Button>
          </InputAdornment>
        ) : (
          ''
        ),
      }}
      {...rest}
    />
  );
};

//  Unmask value given masking char
export const unmaskValue = (value, mask = /\D/g, replace = '') => {
  if (typeof value !== 'string') {
    value = value.toString();
  }
  return value.replace(mask, replace);
};

//Needed if values are hidden so that dashes/spaces dont' show up
const removeNonDigitsFromMask = (mask = []) => {
  return mask.filter((value) => String(value) === String(/\d/));
};

// Custom Mask Input wrapper component
const CustomMaskInput = (props) => {
  const { inputRef, ...rest } = props;

  return (
    <MaskedInput
      ref={(ref) => (ref ? inputRef(ref.inputElement) : null)}
      {...rest}
    />
  );
};

export default TextMaskCustom;
