import { BaseClass } from 'utilities';
import moment from 'moment';

const BankTransferModel = class extends BaseClass {
  constructor(props = {}) {
    super(props);
    this.nameOnAccount = props.nameOnAccount || '';
    this.routingNumber = props.routingNumber || '';
    this.accountNumber = props.accountNumber || '';
    this.accountType = props.accountType || 'CK';
    this.effectiveDate = props.effectiveDate || moment().format('MM/DD/YYYY');
  }
};

export default BankTransferModel;
