// --- Imports --- //

// --- Yup/Validation Imports --- //
import * as yup from 'yup';
import { alpha_num_limited, zip } from 'utilities/validation/regex';

//Required Address Schema
const RequiredAddressSchema = yup.object().shape({
  lineOne: yup
    .string()
    .label('Address Line 1')
    .matches(alpha_num_limited.regex, alpha_num_limited.message)
    .required(),
  lineTwo: yup
    .string()
    .label('Address Line 2')
    .matches(alpha_num_limited.regex, alpha_num_limited.message),
  zip: yup.string().label('Zip').matches(zip.regex, zip.message).required(),
  city: yup
    .string()
    .label('City')
    .matches(alpha_num_limited.regex, alpha_num_limited.message)
    .required(),
  state: yup.string().label('State').required(),
});

//Not Required Address Schema
export const NotRequiredAddressSchema = yup.object().shape({
  lineOne: yup
    .string()
    .label('Address Line 1')
    .matches(alpha_num_limited.regex, alpha_num_limited.message),
  lineTwo: yup
    .string()
    .label('Address Line 2')
    .matches(alpha_num_limited.regex, alpha_num_limited.message),
  zip: yup.string().label('Zip').matches(zip.regex, zip.message),
  city: yup
    .string()
    .label('City')
    .matches(alpha_num_limited.regex, alpha_num_limited.message),
  state: yup.string().label('State'),
});

const poboxRegex = new RegExp('([pP].?[oO].?)\\s([bB]ox)', 'g');

export const PrimaryAddressSchema = yup.object().shape({
  lineOne: yup
    .string()
    .label('Address Line 1')
    .matches(alpha_num_limited.regex, alpha_num_limited.message)
    .test(
      'isPOBox',
      '${path} cannot be a P.O. Box',
      //(value) => !poboxRegex.test(value)
      (value) =>
        !(
          value.toLowerCase().includes('po box') ||
          value.toLowerCase().includes('p.o. box') ||
          value.toLowerCase().includes('po. box') ||
          value.toLowerCase().includes('p.o box')
        )
    )
    .required(),
  lineTwo: yup
    .string()
    .label('Address Line 2')
    .matches(alpha_num_limited.regex, alpha_num_limited.message),
  zip: yup.string().label('Zip').matches(zip.regex, zip.message).required(),
  city: yup
    .string()
    .label('City')
    .matches(alpha_num_limited.regex, alpha_num_limited.message)
    .required(),
  state: yup.string().label('State').required(),
});

export default RequiredAddressSchema;
