import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import palette from 'themes/palette';

//Material UI
import { Box, Grid, CircularProgress, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

//Icons
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ErrorIcon from '@material-ui/icons/Error';

//Modules
import { PaymentForm } from 'modules/PaymentForm';

import { useAuth } from 'services/AuthService';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '40px',
    maxWidth: '800px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 10px',
    },
  },
  title: {
    fontSize: '35px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '25px',
    },
    color: 'white',
    fontWeight: 600,
    letterSpacing: '1px',
    textAlign: 'center',
  },
  hero: {
    height: '20vh',
    width: '100%',
    backgroundImage: 'url(/images/forest.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  subText: {
    fontWeight: 300,
    fontSize: '120%',
  },

  section: {
    boxSizing: 'border-box',
    backgroundColor: 'white',
    borderLeft: '5px solid ' + palette.brick.light,
    padding: '25px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',

    color: palette.highway.main,
    marginBottom: '25px',
  },

  sectionTitle: {
    color: palette.highway.main,
    fontWeight: 700,
    fontSize: '25px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const PaymentFormView = (props) => {
  const classes = useStyles();

  const { isAuthenticated, getTokenForApi, handleLogin, user } = useAuth();

  const location = useLocation();
  const [paymentType, setPaymentType] = useState('normal');
  const [loginOpen, setLoginOpen] = useState(false);

  useEffect(() => {
    if (!isAuthenticated && !loginOpen) {
      setLoginOpen(true);
      handleLogin();
    }
  });

  useEffect(() => {
    if (location.pathname.toLowerCase().includes('glonly')) {
      setPaymentType('gl');
    } else {
      setPaymentType('normal');
    }
  }, []);

  return (
    <Box>
      <Box className={classes.hero}>
        <Box className={clsx('sentinel', classes.title)}>
          {paymentType === 'normal'
            ? 'Make a Payment'
            : paymentType === 'gl'
            ? 'Make a Payment to GL: AST-Otr Online Payment Portal'
            : ''}
        </Box>
      </Box>
      <Box className={classes.container}>
        {paymentType === 'gl' ? (
          user.userPrincipalName &&
          user.userPrincipalName.toLowerCase() !==
            'LSIPaymentAppUser@nwcu.com'.toLowerCase() ? (
            <Box className={classes.section}>
              <Box className={clsx('sentinel', classes.sectionTitle)}>
                <ChatBubbleIcon style={{ marginRight: '7px' }} />
                Please Note
              </Box>
              <Box className={classes.subText}>
                <Box style={{ marginBottom: '15px' }}>
                  This page is appropriate for, among other things, Charge Off
                  Accounts, Visa Account Payments, and multi-account
                  disbursements.
                </Box>
                <Box>
                  Payments made through this page require you to manually
                  disperse those payment funds from the AST-Otr Online Payment
                  Portal GL to the member's acct(s). No payments are
                  automatically applied by the Payment Portal when using this
                  page.
                </Box>
              </Box>
            </Box>
          ) : (
            <Box className={classes.section}>
              <Box className={clsx('sentinel', classes.sectionTitle)}>
                <ErrorIcon style={{ marginRight: '7px' }} />
                Oops!
              </Box>
              <Box className={classes.subText}>
                <Box style={{ marginBottom: '15px' }}>
                  Looks like you do not have permission to do a GL Only payment.
                </Box>
              </Box>
            </Box>
          )
        ) : (
          ''
        )}

        {user.userPrincipalName &&
        user.userPrincipalName.toLowerCase() ===
          'LSIPaymentAppUser@nwcu.com'.toLowerCase() &&
        paymentType === 'gl' ? (
          ''
        ) : (
          <PaymentForm type={paymentType} />
        )}
      </Box>
    </Box>
  );
};

export default PaymentFormView;
