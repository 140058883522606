import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';

//Material UI
import {
  Box,
  Grid,
  CircularProgress,
  Button,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

//Themes
import palette from 'themes/palette';

//Formik
import { TextField } from 'formik-material-ui';
import { Formik, Form, useFormikContext } from 'formik';

//Serivces
import { useCatalogService } from 'services/CatalogService';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginBottom: '15px',
    // color: '#333333',
    fontWeight: '500',
    fontSize: '120%',
  },
}));

const Address = (props) => {
  const classes = useStyles();
  var prefix = props.prefix ? `${props.prefix}.` : '';
  //State Hooks
  const [states, setStates] = useState([]);

  //Services
  const catalogService = useCatalogService();

  useEffect(() => {
    catalogService.getStates().then((response) => {
      setStates(response.data);
    });
  }, []);

  return (
    <Box>
      <Grid container style={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          <Box className={classes.subtitle}>Billing Address:</Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            name={prefix + 'lineOne'}
            label="Address Line One"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name={prefix + 'lineTwo'}
            label="Address Line Two"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            name={prefix + 'city'}
            label="City"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            select
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: true }}
            name={prefix + 'state'}
            label="State"
            fullWidth
            variant="outlined"
          >
            {states.map((state, index) => {
              return (
                <option value={state.code} key={index}>
                  {state.name}
                </option>
              );
            })}
          </TextField>
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            name={prefix + 'zip'}
            label="Zip"
            fullWidth
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Address;
