// --- Imports --- //
import { v4 as uuid } from 'uuid';
import { NotInterestedIcon as MissingIcon } from '@material-ui/icons/NotInterested';

// -- //
import { BaseClass as Base } from 'utilities';

// -- Empty Component -- //
const Empty = () => {
  console.warn('Component specified');
  return '';
};

// -- Route Class --- //
const Config = class extends Base {
  constructor(props = {}) {
    super();
    this._route = {
      component: props.component || Empty,
      layout: props.layout || null,
      path: props.paths || [],
    };

    this._link = {
      title: props.title || '',
      icon: props.icon || MissingIcon,
      href: props.href || '/not-found',
    };

    this.isProtected = props.isProtected || false;
    this.key = uuid();
  }
  getLink() {
    return { ...this._link };
  }

  getRoute() {
    return { ...this._route };
  }
};

export default Config;
