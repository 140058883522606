import { BaseClass } from 'utilities';
import moment from 'moment';

const ErrorModel = class extends BaseClass {
  constructor(props = {}) {
    super(props);
    this.display = props.display || '';
    this.errorCode = props.errorCode || '';
    this.message = props.message || '';
  }
};

export default ErrorModel;