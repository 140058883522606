const brick = {
  50: '#F4E8E4',
  100: '#E3C5BC',
  200: '#D19F8F',
  300: '#BF7962',
  400: '#B15C41',
  500: '#A33F1F',
  600: '#9B391B',
  700: '#913117',
  800: '#882912',
  900: '#771B0A',
  A100: '#FFB2A7',
  A200: '#FF8574',
  A400: '#FF5841',
  A700: '#FF4128'
};

export default brick;
