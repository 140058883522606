import { colors } from '@material-ui/core';
import forest from './colors/forest';
import river from './colors/river';
import brick from './colors/brick';
import mountain from './colors/mountain';
import highway from './colors/highway';
import beach from './colors/beach';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: forest[900],
    main: forest[600],
    light: forest[100],
  },
  secondary: {
    contrastText: white,
    dark: river[900],
    main: river[600],
    light: river[100],
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  background: {
    default: '#F4F6F8',
    paper: white,
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],

  /////////   CUSTOM COLORS   /////////
  //Cannot be used for button color, but
  //can be accessed by importing palette
  //and doing palette.palette.brick.main
  //in useStyles or other CSS

  forest: {
    main: forest[500],
    mediumlight: forest[400],
    light: forest[300],
    verylight: forest[50],
    dark: forest[900],
  },
  river: {
    main: river[500],
    light: river[300],
    verylight: river[100],
    mediumlight: river[400],
    dark: river[700],
    veryverylight: river[50],
  },
  brick: {
    main: brick[500],
    light: brick[300],
    dark: brick[700],
  },
  mountain: {
    main: mountain[500],
    light: mountain[300],
    dark: mountain[700],
  },
  highway: {
    main: highway[500],
    mediumlight: highway[400],
    light: highway[300],
    dark: highway[700],
  },
  beach: {
    main: beach[500],
    light: beach[300],
    dark: beach[700],
  },
};
