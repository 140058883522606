// --- Imports --- //
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

// --- Material Ui Imports --- //

// --- Custom Component Imports --- //

// --- Utilities Import --- //

// --- Redux Imports --- //
// import { connect } from 'react-redux';

// --- Store Import --- //

// --- Store Configurations --- //
// const mapStateToProps = (state, ownProps) => {
//   return {
//     ...ownProps,
//   };
// };

// const mapDispatchToProps = (dispatch, ownProps) => {
//   return {
//     ...ownProps,
//   };
// };

// --- Styles --- //

// --- Component --- //
const PublicRoute = props => {
  const { children, component: Component, layout: Layout, ...rest } = props;
  const hasLayout = Boolean(Layout);

  return (
    <Route
      {...rest}
      render={matchProps => {
        if (hasLayout) {
          return (
            <Layout>
              <Component {...matchProps} />
            </Layout>
          );
        }

        return <Component {...matchProps} />;
      }}
    />
  );
};

// --- Component Props --- //
PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

// PublicRoute.defaultProps  = {
//
// }

// --- Store Connection --- //
// const StatefulPublicRoute = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(PublicRoute);

// --- Exports --- //
export default PublicRoute;
// export default StatefulPublicRoute;
