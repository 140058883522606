import React, { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import * as yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

//Material UI
import {
  Box,
  Grid,
  CircularProgress,
  Button,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField as MuiTextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

//Components
import {
  CustomPopUp,
  CustomPopUpAlert,
  CurrencyInput,
  TextMaskCustom,
} from 'components';

//Icons
import PersonIcon from '@material-ui/icons/Person';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PaymentIcon from '@material-ui/icons/Payment';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LockIcon from '@material-ui/icons/Lock';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

//Themes
import palette from 'themes/palette';

//Formik
import { TextField } from 'formik-material-ui';
import { Formik, Form, useFormikContext } from 'formik';

//Modules
import PaymentFormSchema from './PaymentFormSchema';
import PaymentFormModel from './PaymentFormModel';
import { BankTransfer } from 'modules/BankTransfer';
import { DebitCard } from 'modules/DebitCard';
import { default as OverrideDialog } from './OverrideDialog';

//Services
import { usePaymentService } from 'services/PaymentService';
import { useCatalogService } from 'services/CatalogService';
import { useAuth } from 'services/AuthService';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '40px',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 20px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 10px',
    },
  },
  title: {
    fontSize: '35px',
    color: 'white',
    fontWeight: 600,
    letterSpacing: '1px',
  },
  section: {
    boxSizing: 'border-box',
    backgroundColor: 'white',
    borderLeft: '5px solid ' + palette.forest.light,
    padding: '25px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',

    color: palette.highway.main,
    marginBottom: '25px',
  },

  sectionTitle: {
    color: palette.highway.main,
    fontWeight: 700,
    fontSize: '25px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  radioContainer: {
    display: 'flex',
    marginBottom: '20px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  radioBox: {
    boxSizing: 'content-box',
    border: '1px solid ' + palette.river.verylight,
    margin: '5px',
    padding: '15px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 10px',
      marginBottom: '8px',
    },
    borderRadius: '5px',
    cursor: 'pointer',
    opacity: 0.85,
    '&:focus': {
      border: '3px solid ' + palette.river.dark,
      outline: 'none',
    },
  },

  radioBoxSelected: {
    boxSizing: 'content-box',
    border: '3px solid ' + palette.river.light,
    backgroundColor: palette.river.veryverylight,
    opacity: 1,
    // margin: 0,
    [theme.breakpoints.down('xs')]: {
      marginBottom: '8px',
    },
  },
  radioTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: 500,
  },
  radioDescription: { paddingTop: '8px', fontSize: '85%', fontWeight: 300 },
  subtitle: {
    marginBottom: '15px',
    fontWeight: '500',
    fontSize: '120%',
  },
  error: {
    color: '#e53935',
    marginLeft: '14px',
    marginRight: '14px',

    fontSize: '13px',
    letterSpacing: '0.33px',
    fontWeight: 400,
  },
  link: {
    textDecoration: 'none',
    color: '#ACBFAA',
    fontWeight: 400,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const RadioBox = (props) => {
  const classes = useStyles();
  const { name, value, icon, title, description, ...rest } = props;
  const { values, setValues } = useFormikContext();

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (values[name] === value) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [values]);

  const radioBoxClick = () => {
    setValues({
      ...values,
      paymentType: value,
    });
  };

  return (
    <Box
      className={clsx(
        classes.radioBox,
        selected ? classes.radioBoxSelected : ''
      )}
      onClick={radioBoxClick}
      {...rest}
      tabIndex={0}
      role="button"
      onKeyDown={(e) => {
        if (e.which === 13) {
          radioBoxClick();
        }
      }}
    >
      <Box className={classes.radioTitle}>
        {icon} {title}
      </Box>
      <Box className={classes.radioDescription}>{description}</Box>
    </Box>
  );
};

const loading = (loadingMessage = 'Loading...') => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 300,
      }}
    >
      <CircularProgress style={{ marginBottom: '20px' }} color="secondary" />
      {loadingMessage}
    </Box>
  );
};

const PaymentForm = (props) => {
  const classes = useStyles();
  const { type, setOverridePrecheckError, setPrecheckOverrideDialog, setPrecheckErrorMessage } = props;

  //Redux
  const dispatch = useDispatch();
  const branches = useSelector((state) => state.reducer.branches);
  const selectedBranch = useSelector((state) => state.reducer.selectedBranch);

  //Services
  const paymentService = usePaymentService();
  const catalogService = useCatalogService();
  const { user } = useAuth();

  //custom pop up alert
  const {
    openCustomPopUpAlert,
    closeCustomPopUpAlert,
    setPopUpAlertContent,
    setPopUpAlertTitle,
    setPopUpAlertType,
  } = props.popupActions;

  //Yes/no popup
  const [customPopUp, setCustomPopUp] = useState(false);
  const [negativeCustomPopup, setNegativeCustomPopup] = useState(false);
  const [negativeCustomPopupContent, setNegativeCustomPopupContent] = useState('');
  const [negativeCustomPopupActions, setNegativeCustomPopupActions] = useState('');
  const [popUpTitle, setPopUpTitle] = useState('');
  const [popUpContent, setPopUpContent] = useState('');
  const [popUpActions, setPopUpActions] = useState('');
  //Custom PopUp Helpers
  const openCustomPopUp = () => setCustomPopUp(true);
  const closeCustomPopUp = () => setCustomPopUp(false);
  const openNegativeCustomPopUp = () => setNegativeCustomPopup(true);
  const closeNegativeCustomPopUp = () => setNegativeCustomPopup(false);

  const {
    values,
    setValues,
    errors,
    touched,
    validateField,
    validateForm,
    setErrors,
    setTouched,
    ...rest
  } = useFormikContext();

  const [validated, setValidated] = useState(false);
  const [paymentTypes, setPaymentTypes] = useState([]);

  useEffect(() => {
    catalogService.getPaymentTypes().then((response) => {
      setPaymentTypes(response.data);
    });
  }, []);

  useEffect(() => {
    if (paymentTypes.length === 1) {
      setValues({ ...values, paymentType: paymentTypes[0] });
    }
  }, [paymentTypes]);

  useEffect(() => {
    if (type === 'gl') {
      setValues({ ...values, feeAmount: 0, applyFee: false });
    }
  }, [type]);

  //Changes events based on the dropdown
  const handleBranchChange = (event) => {
    //Get Branch Name
    const selectedBranchName = branches.find(
      (branch) => branch.coreId === event.target.value
    ).name;
    //Set branch in redux
    dispatch(
      actions.setSelectedBranch({
        name: selectedBranchName,
        id: event.target.value,
      })
    );
  };

  //When you click OVERRIDE button
  // If an override is input, the user will need to click 'next' again to revalidate.
  const overridePrecheckError = (vals, resetForm) => {
    setPrecheckOverrideDialog(false);
    setIsOverridden(vals.isOverridden);
    
  };

  const validateInfo = () => {
    openCustomPopUpAlert();
    setPopUpAlertTitle('Loading');
    setPopUpAlertContent(loading('Validating Loan # and Last Name...'));
    setPopUpAlertType('loading');

    const info = {
      accountNumber: parseInt(values.loanNumber),
      lastName: values.lastName.trim(),
      overrideAccountNumber: values.overrideAccountNumber,
      feeAmount: values.feeAmount,
      loggedInUser: user.mail ? user.mail : user.userPrincipalName,
      gLOnlyFlag: type === 'gl' ? true : false,
      isOverridden: values.isOverridden || false,
    };

    paymentService.validateLoanNumber(info).then((response) => {
      let message = '';
      if (response.data.status === 200) {
        closeCustomPopUpAlert();
        if (type !== 'gl') {
          setNegativeCustomPopupActions(<Fragment>
            <Button onClick={() => {
              setValues({ ...values, feeAccountNegative: response.data.feeAccountNegative, payAccountNegative: response.data.payAccountNegative, overrideNegativeBalance: true });
              closeNegativeCustomPopUp();
              setValidated(true);
            }} variant="contained" color="primary">
              Yes
            </Button>
            <Button
              onClick={() => {
                window.location.reload();
                //closeNegativeCustomPopUp(false);
              }}
              variant="outlined"
              color="primary"
            >
              No
            </Button>
          </Fragment>);
          if (response.data.feeAccountNegative !== null && response.data.feeAccountNegative > 0 && response.data.payAccountNegative !== null && response.data.payAccountNegative > 0) {
            message = 'The fee account and pay account for this transaction have negative balances. Would you like to continue this transaction and add the negative balance amounts to the total amount charged?';
            setNegativeCustomPopupContent(message);
            openNegativeCustomPopUp();
          }
          else if (response.data.feeAccountNegative !== null && response.data.feeAccountNegative > 0) {
            message = 'The fee account for this transaction has a negative balance. Would you like to continue this transaction and add the negative balance amount to the total amount charged?';
            setNegativeCustomPopupContent(message);
            openNegativeCustomPopUp();
          }
          else if (response.data.payAccountNegative !== null && response.data.payAccountNegative > 0) {
            message = 'The pay account for this transaction has a negative balance. Would you like to continue this transaction and add the negative balance amount to the total amount charged?';
            setNegativeCustomPopupContent(message);
            openNegativeCustomPopUp();
          }
          else {
            setValidated(true);
          }
        }
        else {
          setValidated(true);
        }
      } else {
        if (type !== 'gl' && ((response.data.feeAccountNegative !== null && response.data.feeAccountNegative > 0) || (response.data.payAccountNegative !== null && response.data.payAccountNegative > 0))) {
          closeCustomPopUpAlert();
          setNegativeCustomPopupActions(<Fragment>
            <Button onClick={() => {
              setValues({ ...values, feeAccountNegative: response.data.feeAccountNegative, payAccountNegative: response.data.payAccountNegative, overrideNegativeBalance: true });
              closeNegativeCustomPopUp();
              setValidated(true);
            }} variant="contained" color="primary">
              Yes
            </Button>
            <Button
              onClick={() => {
                window.location.reload();
                //closeNegativeCustomPopUp(false);
              }}
              variant="outlined"
              color="primary"
            >
              No
            </Button>
          </Fragment>);
          if (response.data.feeAccountNegative !== null && response.data.feeAccountNegative > 0 && response.data.payAccountNegative !== null && response.data.payAccountNegative > 0) {
            message = 'The fee account and pay account for this transaction have negative balances. Would you like to continue this transaction and add the negative balance amounts to the total amount charged?';
            setNegativeCustomPopupContent(message);
            openNegativeCustomPopUp();
          }
          else if (response.data.feeAccountNegative !== null && response.data.feeAccountNegative > 0) {
            message = 'The fee account for this transaction has a negative balance. Would you like to continue this transaction and add the negative balance amount to the total amount charged?';
            setNegativeCustomPopupContent(message);
            openNegativeCustomPopUp();
          }
          else if (response.data.payAccountNegative !== null && response.data.payAccountNegative > 0) {
            message = 'The pay account for this transaction has a negative balance. Would you like to continue this transaction and add the negative balance amount to the total amount charged?';
            setNegativeCustomPopupContent(message);
            openNegativeCustomPopUp();
          }
          else {
            setValidated(true);
          }
        }
        else {
          //If failure and overridable, open Override Dialog
          if (response.data.isOverridable === true) {
            setOverridePrecheckError(() => overridePrecheckError);
            setPrecheckOverrideDialog(true);
            closeCustomPopUpAlert();
            setPrecheckErrorMessage(
              <Box>
              {response.data.errors.map(d => (<li >{d.message}</li>))}
              <br /><br />
              {response.data.sessionId}
            </Box>
            );

            //Log the message shown to the user
            const messageObject = {
              sessionId: values.sessionId,
              source: 'Payment.Client',
              message: response.data.message,
            };
            paymentService
              .logErrorResponse(messageObject)
              .then((response) => { });
          } else {
            //If failure and not overridable, just display error message
            setPopUpAlertTitle('Error');
            setPopUpAlertContent(
              <Box>
                  {response.data.errors.map(d => (<li >{d.message}</li>))}
                  <br /><br />
                  {response.data.errors.find(e => e.errorCode == 1014 || e.errorCode == 1007) ? 'Please contact Member Solutions for assistance.' : ''}
                  <br /><br />
                  {response.data.sessionId}

              </Box>
            );
            setPopUpAlertType('error');

            //Log the message shown to the user
            const messageObject = {
              sessionId: values.sessionId,
              source: 'Payment.Client',
              message: response.data.message,
            };
            paymentService
              .logErrorResponse(messageObject)
              .then((response) => { });
          }
          //setPopUpAlertTitle('Error');
          //setPopUpAlertContent(response.data.message + '.');
          //setPopUpAlertType('error');
        }
      }
    });

    //Close the confirm just in case it is open
    closeCustomPopUp();
  };

  const confirmNumberOverride = () => {
    openCustomPopUp();
  };

  const nextButtonClick = () => {
    //first we validate
    validateForm().then((errors) => {
      if (errors.lastName || errors.loanNumber) {
        const errorObject = {
          lastName: errors.lastName,
          loanNumber: errors.loanNumber,
        };
        setErrors(errorObject);
        setTouched(errorObject);
      } else {
        if (values.overrideAccountNumber === true) {
          //If box is checked
          confirmNumberOverride();
        } else {
          //If box isn't checked
          validateInfo();
        }
      }
    });
  };

  const handleFeeChecked = () => {
    const newApplyFee = !values.applyFee;
    if (newApplyFee === false) {
      setValues({ ...values, applyFee: newApplyFee, feeAmount: 0 });
    } else {
      setValues({ ...values, applyFee: newApplyFee, feeAmount: 10 });
    }
  };

  const overrideAccountNumber = () => {
    const override = !values.overrideAccountNumber;
    setValues({ ...values, overrideAccountNumber: override });
  };

  const overrideMaxAmount = () => {
    const override = !values.overrideMaxAmount;
    setValues({ ...values, overrideMaxAmount: override });
  };

  const overrideBillingAddress = () => {
    const override = !values.overrideBillingAddress;
    setValues({ ...values, overrideBillingAddress: override });
  };

  const setIsOverridden = (personNumber) => {
    setValues({ ...values, isOverridden: personNumber });
  };

  return (
    <Box>
      <CustomPopUp
        open={customPopUp}
        title={
          <Box className={classes.flex}>
            <WarningRoundedIcon
              style={{ marginRight: '10px' }}
              fontSize="large"
            />
            Confirmation
          </Box>
        }
        actions={
          <Fragment>
            <Button onClick={validateInfo} variant="contained" color="primary">
              Yes
            </Button>
            <Button
              onClick={() => {
                closeCustomPopUp(false);
              }}
              variant="outlined"
              color="primary"
            >
              No
            </Button>
          </Fragment>
        }
        alert
        disableClose
      >
        <Box style={{ marginBottom: '18px' }}>
          This override should only be used in rare situations, such as
          collecting an appraisal fee, or other situations where you are
          collecting a fee for a person who is not yet a member. If they are a
          member, you can use their primary savings account number as the
          validation account number in this field.
        </Box>
        Are you sure you want to continue overriding this account number?
      </CustomPopUp>
      <CustomPopUp
        open={negativeCustomPopup}
        title="Negative Balances"
        actions={negativeCustomPopupActions}
        alert
        disableClose
      >
        {negativeCustomPopupContent}
      </CustomPopUp>
      <Box
        className={classes.section}
        style={{
          borderLeft: !validated
            ? '5px solid ' + palette.forest.light
            : '5px solid ' + palette.river.light,
        }}
      >
        <Box className={clsx('sentinel', classes.sectionTitle)}>
          <LocationOnIcon style={{ marginRight: '7px' }} />
          Location
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MuiTextField
              select
              id="branch"
              name="branch"
              label="Branch"
              variant="outlined"
              fullWidth
              // style={{ marginRight: '10px', minWidth: '250px' }}
              value={selectedBranch.id}
              onChange={handleBranchChange}
            >
              {branches.map((branch) => (
                <MenuItem key={branch.coreId} value={branch.coreId}>
                  {branch.name}
                </MenuItem>
              ))}
            </MuiTextField>
          </Grid>
        </Grid>
      </Box>
      <Box
        className={classes.section}
        style={{
          borderLeft: !validated
            ? '5px solid ' + palette.forest.light
            : '5px solid ' + palette.river.light,
        }}
      >
        <Box className={clsx('sentinel', classes.sectionTitle)}>
          <PersonIcon style={{ marginRight: '7px' }} />
          Personal Info
        </Box>
        <Grid container spacing={3}>
          {type === 'gl' ? (
            <Fragment>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  InputProps={{
                    endAdornment: validated ? (
                      <InputAdornment position="end">
                        <LockIcon />
                      </InputAdornment>
                    ) : (
                        ''
                      ),
                    readOnly: validated ? true : false,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="loanNumber"
                  label="Loan Number"
                  variant="outlined"
                  InputProps={{
                    endAdornment: validated ? (
                      <InputAdornment position="end">
                        <LockIcon />
                      </InputAdornment>
                    ) : (
                        ''
                      ),
                    readOnly: validated ? true : false,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4} style={{ display: 'flex' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.overrideAccountNumber}
                      style={{ marginRight: '10px' }}
                      color="primary"
                      onChange={!validated ? overrideAccountNumber : () => { }}
                    />
                  }
                  label="Override required account number?"
                />
              </Grid>
            </Fragment>
          ) : (
              <Fragment>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="lastName"
                    label="Last Name"
                    variant="outlined"
                    InputProps={{
                      endAdornment: validated ? (
                        <InputAdornment position="end">
                          <LockIcon />
                        </InputAdornment>
                      ) : (
                          ''
                        ),
                      readOnly: validated ? true : false,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="loanNumber"
                    label="Loan Number"
                    variant="outlined"
                    InputProps={{
                      endAdornment: validated ? (
                        <InputAdornment position="end">
                          <LockIcon />
                        </InputAdornment>
                      ) : (
                          ''
                        ),
                      readOnly: validated ? true : false,
                    }}
                    fullWidth
                  />
                </Grid>{' '}
              </Fragment>
            )}
          <Grid item xs={12} sm={6}>
            <TextField
              name="email"
              label="Email (optional)"
              variant="outlined"
              fullWidth
              helperText="Enter an email if you would like to receive a confirmation email of your payment."
            />
          </Grid>
        </Grid>
      </Box>
      <Button
        variant="contained"
        color="primary"
        name="continue"
        style={{ display: validated ? 'none' : 'flex' }}
        onClick={nextButtonClick}
      >
        Next
      </Button>

      {validated ? (
        <Box>
          <Box className={classes.section}>
            <Box className={clsx('sentinel', classes.sectionTitle)}>
              <AttachMoneyIcon style={{ marginRight: '10px' }} />
              Fees & Payments
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="feeAmount"
                  label="Fee"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    ),
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.applyFee}
                      style={{ marginRight: '10px' }}
                      color="primary"
                      onChange={handleFeeChecked}
                    />
                  }
                  label="Apply Fee?"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CurrencyInput
                  name="paymentAmount"
                  label="Loan Payment Amount"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              {user.userPrincipalName &&
                user.userPrincipalName.toLowerCase() !==
                'LSIPaymentAppUser@nwcu.com'.toLowerCase() ? (
                  <Grid item xs={12} sm={6} style={{ display: 'flex' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.overrideMaxAmount}
                          style={{ marginRight: '10px' }}
                          color="primary"
                          onChange={overrideMaxAmount}
                        />
                      }
                      label="Override Maximum Amount?"
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6}></Grid>
                )}
              {
                values.feeAccountNegative !== null && values.feeAccountNegative > 0 ?
                  (<Grid item xs={12} sm={6}>
                    <TextField
                      name="feeAccountNegative"
                      label="Fee Account Negative Balance"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon />
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                    />
                  </Grid>) : ''}
              {values.payAccountNegative !== null && values.payAccountNegative > 0 ? (<Grid item xs={12} sm={6}>
                <TextField
                  name="payAccountNegative"
                  label="Pay Account Negative Balance"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    ),
                    readOnly: true,
                  }}
                />
              </Grid>) : ''}
            </Grid>
          </Box>
          <Box className={classes.section}>
            <Box className={clsx('sentinel', classes.sectionTitle)}>
              <PaymentIcon style={{ marginRight: '10px' }} />
              Payment Method
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box className={classes.subtitle}>Total Payment Amount:</Box>

                <MuiTextField
                  // label="Total Payment Amount"
                  // variant="outlined"
                  // fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    ),
                    readOnly: true,
                  }}
                  value={(
                    parseFloat(values.feeAmount) +
                    (parseFloat(values.paymentAmount)
                      ? parseFloat(values.paymentAmount)
                      : 0) +
                    (parseFloat(values.feeAccountNegative)
                      ? parseFloat(values.feeAccountNegative)
                      : 0) +
                    (parseFloat(values.payAccountNegative)
                      ? parseFloat(values.payAccountNegative)
                      : 0)
                  )
                    .toFixed(2)
                    .toString()}
                />
              </Grid>
              <Grid item xs={12}>
                {paymentTypes.length > 1 ? (
                  <Box>
                    <Box className={classes.subtitle}>
                      Select a Payment Method:
                    </Box>
                    <Box
                      className={classes.radioContainer}
                      style={{ display: 'flex', marginBottom: '20px' }}
                    >
                      <RadioBox
                        name="paymentType"
                        value="card"
                        title="Debit Card"
                        description="Click this box if your payment method is a Debit Card."
                        icon={
                          <CreditCardIcon style={{ marginRight: '10px' }} />
                        }
                      // style={{ marginRight: '15px' }}
                      />
                      <RadioBox
                        name="paymentType"
                        value="ach"
                        title="Bank Transfer"
                        description="Click this box if your payment method is a Bank Transfer."
                        icon={
                          <AccountBalanceIcon style={{ marginRight: '10px' }} />
                        }
                      />
                    </Box>
                    {errors.paymentType && touched.paymentType ? (
                      <Box className={classes.error}>{errors.paymentType}</Box>
                    ) : (
                        ''
                      )}
                  </Box>
                ) : (
                    ''
                  )}
              </Grid>
            </Grid>

            {values.paymentType === 'card' ? (
              <Box>
                <DebitCard
                  prefix="debitCard"
                  setDebitOverride={props.setDebitOverride}
                />
                {user.userPrincipalName &&
                  user.userPrincipalName.toLowerCase() !==
                  'LSIPaymentAppUser@nwcu.com'.toLowerCase() ? (
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} style={{ display: 'flex' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.overrideBillingAddress}
                              style={{ marginRight: '10px' }}
                              color="primary"
                              onChange={overrideBillingAddress}
                            />
                          }
                          label="Override Billing Address Verification?"
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
              </Box>
            ) : values.paymentType === 'ach' ? (
              <Box>
                <BankTransfer prefix="bankTransfer" />
              </Box>
            ) : (
                  ''
                )}
          </Box>
          <Button
            variant="contained"
            type="submit"
            name="makePayment"
            color="primary"
          >
            Make Payment
          </Button>
        </Box>
      ) : (
          ''
        )}
    </Box>
  );
};

const PaymentFormWrapper = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { type } = props;

  //Redux
  const dispatch = useDispatch();
  const selectedBranch = useSelector((state) => state.reducer.selectedBranch);

  const [paymentInfo, setPaymentInfo] = useState({});
  const [debitOverride, setDebitOverride] = useState(false);

  //Error w override pop up
  const [overrideDialog, setOverrideDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [precheckOverrideDialog, setPrecheckOverrideDialog] = useState(false);
  const [precheckErrorMessage, setPrecheckErrorMessage] = useState('');
  const [overridePrecheckError, setOverridePrecheckError] = useState(() => () => { });

  //Alert Popup
  const [customPopUpAlert, setCustomPopUpAlert] = useState(false);
  const [popUpAlertTitle, setPopUpAlertTitle] = useState('');
  const [popUpAlertContent, setPopUpAlertContent] = useState('');
  const [popUpAlertType, setPopUpAlertType] = useState('neutral');

  //Custom Alert PopUp Helpers
  const openCustomPopUpAlert = () => setCustomPopUpAlert(true);
  const closeCustomPopUpAlert = () => setCustomPopUpAlert(false);

  const popupActions = {
    setPopUpAlertContent: setPopUpAlertContent,
    setPopUpAlertTitle: setPopUpAlertTitle,
    setPopUpAlertType: setPopUpAlertType,
    openCustomPopUpAlert: openCustomPopUpAlert,
    closeCustomPopUpAlert: closeCustomPopUpAlert,
  };

  //Services
  const paymentService = usePaymentService();
  const {
    isAuthenticated,
    getTokenForApi,
    appSettings,
    user,
    handleLogin,
  } = useAuth();

  const [loginOpen, setLoginOpen] = useState(false);

  useEffect(() => {
    if (!isAuthenticated && !loginOpen) {
      setLoginOpen(true);
      handleLogin();
    }
  }, [isAuthenticated, loginOpen]);

  //Redirect to select a branch if no default is set
  useEffect(() => {
    if (isAuthenticated) {
      if (appSettings.defaultBranch && appSettings.defaultBranchName) {
        //Set selected branch in redux
        dispatch(
          actions.setSelectedBranch({
            name: appSettings.defaultBranchName,
            id: appSettings.defaultBranch,
          })
        );
      } else {
        //Redirect to branches page
        history.push({
          pathname: '/branches',
        });
      }
    }
  }, [appSettings, isAuthenticated]);

  //First time you click MAKE PAYMENT button
  const makePayment = (values, resetForm) => {
    // console.log('values', values);
    openCustomPopUpAlert();
    setPopUpAlertTitle('Loading');
    setPopUpAlertContent(loading('Processing...'));
    setPopUpAlertType('loading');

    try {
      const info = {
        sessionId: values.sessionId,
        loggedInUser: user.mail ? user.mail : user.userPrincipalName,
        accountNumber: values.overrideAccountNumber
          ? 0
          : parseInt(values.loanNumber),
        lastName: values.lastName.trim(),
        feeAmount: parseFloat(values.feeAmount),
        branchId: selectedBranch.id.toString(),
        fundType: values.paymentType,
        gLOnlyFlag: type === 'gl' ? true : false,
        overrideBillingAddress: values.overrideBillingAddress,
        overrideDebitCardCheck: debitOverride,
        overrideMaxAmount: values.overrideMaxAmount,
        overrideAccountNumber: values.overrideAccountNumber,
        amount: parseFloat(values.paymentAmount) + parseFloat(values.feeAmount) + (parseFloat(values.feeAccountNegative) ? parseFloat(values.feeAccountNegative) : 0) + (parseFloat(values.payAccountNegative) ? parseFloat(values.payAccountNegative) : 0),
        creditCard: {
          number: values.debitCard.cardNumber.trim(),
          nameOnCard: values.debitCard.nameOnCard.trim(),
          securityCode: values.debitCard.securityCode.trim(),
          expirationDate: values.debitCard.expirationDate,
          expirationMonth: values.debitCard.expirationDate.substring(0, 2),
          expirationYear: values.debitCard.expirationDate.substring(2, 6),
          billingAddress: values.debitCard.billingAddress,
        },
        ach: {
          accountNumber: values.bankTransfer.accountNumber.trim(),
          accountType: values.bankTransfer.accountType,
          effectiveDate: moment(values.bankTransfer.effectiveDate).toDate(),
          nameOnAccount: values.bankTransfer.nameOnAccount.trim(),
          routingNumber: values.bankTransfer.routingNumber.trim(),
        },
        overrideNegativeBalance: values.overrideNegativeBalance,
        feeAccountNegative: values.feeAccountNegative,
        payAccountNegative: values.payAccountNegative,
        isOverridden: values.isOverridden,
      };

      setPaymentInfo(info);

      paymentService
        .makePayment(info)
        .then((response) => {
          if (response.data.status === 200) {
            //If success, redirect to success page
            history.push({
              pathname: '/success',
              state: { ...response.data, email: values.email },
            });
          } else {
            //If failure and overridable, open Override Dialog
            if (response.data.isOverridable === true) {
              setOverrideDialog(true);
              closeCustomPopUpAlert();
              setErrorMessage(
                <Box>
                  {response.data.errors.map(d => (<li >{d.message}</li>))}
                  <br /><br />
                  {response.data.sessionId}
                </Box>
              );

              //Log the message shown to the user
              const messageObject = {
                sessionId: values.sessionId,
                source: 'Payment.Client',
                message: response.data.message,
              };
              paymentService
                .logErrorResponse(messageObject)
                .then((response) => { });
            } else {
              //If failure and not overridable, just display error message
              setPopUpAlertTitle('Error');
              setPopUpAlertContent(
                <Box>
                  {response.data.errors.map(d => (<li >{d.message}</li>))}
                  <br /><br />
                  {response.data.errors.find(e => e.errorCode == 1014 || e.errorCode == 1007) ? 'Please contact Member Solutions for assistance.' : ''}
                  <br /><br />
                  {response.data.sessionId}
                </Box>
              );
              setPopUpAlertType('error');

              //Log the message shown to the user
              const messageObject = {
                sessionId: values.sessionId,
                source: 'Payment.Client',
                message: response.data.message,
              };
              paymentService
                .logErrorResponse(messageObject)
                .then((response) => { });
            }
            //Set the form values back to what was entered
            resetForm({ values: values });
          }
        })
        .catch((error) => {
          setPopUpAlertTitle('Error');
          setPopUpAlertContent(
            <Box>{'Looks like something went wrong.'} </Box>
          );
          setPopUpAlertType('error');

          //Log the message shown to the user
          const messageObject = {
            sessionId: values.sessionId,
            source: 'Payment.Client',
            message: 'Looks like something went wrong.',
          };
          paymentService.logErrorResponse(messageObject).then((response) => { });

          //Set the form values back to what was entered
          resetForm({ values: values });
        });
    } catch {
      console.log('Something went wrong');
    }
  };

  //When you click OVERRIDE button
  const overridePaymentError = (values, resetForm) => {
    //Set loading wheel
    openCustomPopUpAlert();
    setPopUpAlertTitle('Loading');
    setPopUpAlertContent(loading());
    setPopUpAlertType('loading');

    console.log('values: ' + values)
    //Take what we already have saved from the first payment click and add person number
    const newPaymentInfo = {
      ...paymentInfo,
      isOverridden: true,
    };

    //Call API to override payment again, this time with the personDNANumber
    paymentService.makePayment(newPaymentInfo).then((response) => {
      if (response.data.status === 200) {
        //If success, redirect to success page
        history.push({
          pathname: '/success',
          state: { ...response.data, email: values.email },
        });
      } else {
        //If failure, if overridable, bring back up the override dialog
        if (response.data.isOverridable === true) {
          setOverrideDialog(true);
          closeCustomPopUpAlert();
          setErrorMessage(
            <Box>
              {response.data.errors.map(d => (<li >{d.message}</li>))}
                <br /><br />
                {response.data.sessionId}
            </Box>
          );
        } else {
          //If failure but not overridable, just display error with button to close
          setPopUpAlertTitle('Error');
          setPopUpAlertContent(
            <Box>
              {response.data.errors.map(d => (<li >{d.message}</li>))}
                <br /><br />
                {response.data.errors.find(e => e.errorCode == 1014 || e.errorCode == 1007) ? 'Please contact Member Solutions for assistance.' : ''}
                <br /><br />
                {response.data.sessionId}
            </Box>
          );
          setPopUpAlertType('error');
        }
      }

      //Set the form values back to what was entered
      resetForm({ values: values });
    });

    //Close override dialog
    setOverrideDialog(false);
  };

  return (
    <Box>
      <CustomPopUpAlert
        open={customPopUpAlert}
        title={popUpAlertTitle}
        type={popUpAlertType}
        onClose={closeCustomPopUpAlert}
        disableClose
        fullWidth
      >
        {popUpAlertContent}
      </CustomPopUpAlert>

      <Formik
        initialValues={{isOverridden: false }}
        validationSchema={yup.object().shape({
          isOverridden: yup
            .boolean()
            .required(),
        })}
        onSubmit={(values, { resetForm }) => {
          console.log('submitted values', values);
          overridePaymentError(values, resetForm);
          setOverrideDialog(false);
          resetForm();
        }}
      >
        <Form>
          <OverrideDialog
            setOverrideDialog={setOverrideDialog}
            overrideDialog={overrideDialog}
            errorMessage={errorMessage}
          />
        </Form>
      </Formik>
      <Formik
        initialValues={{isOverridden: false }}
        validationSchema={yup.object().shape({
          isOverridden: yup
            .boolean()
            .required(),
        })}
        onSubmit={(values, { resetForm }) => {
          overridePrecheckError(values, resetForm);
          setPrecheckOverrideDialog(false);
          resetForm();
        }}
      >
        <Form>
          <OverrideDialog
            setOverrideDialog={setPrecheckOverrideDialog}
            overrideDialog={precheckOverrideDialog}
            errorMessage={precheckErrorMessage}
          />
        </Form>
      </Formik>
      <Formik
        initialValues={new PaymentFormModel().toObject()}
        validationSchema={PaymentFormSchema}
        onSubmit={(values, { resetForm }) => {
          makePayment(values, resetForm);
        }}
      >
        <Form>
          <PaymentForm
            popupActions={popupActions}
            type={type}
            setDebitOverride={setDebitOverride}
            setPrecheckErrorMessage={setPrecheckErrorMessage}
            setPrecheckOverrideDialog={setPrecheckOverrideDialog}
            setOverridePrecheckError={setOverridePrecheckError}
          />
        </Form>
      </Formik>
    </Box>
  );
};

export default PaymentFormWrapper;
