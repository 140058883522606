import React from 'react';

// --- Material --- //
import { Typography, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    flexDirection: 'column',
  },
  content: {
    height: 'max-content',
  },
  title: {
    // marginTop: theme.spacing(3),
    fontSize: 48,
  },
  subtitle: {
    // marginTop: theme.spacing(3),
    lineHeight: '26px',
    fontSize: 24,
    letterSpacing: 5,
    textTransform: 'uppercase',
  },
}));

const Landing = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h3" color="primary">
          Logout Successful
        </Typography>
      </div>
    </div>
  );
};

export default Landing;
