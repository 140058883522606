// --- Imports --- //
import React, { useEffect, useState } from 'react';
import { getStoredPath, clearStoredPath, useAuth } from 'services/AuthService';
import { useHistory } from 'react-router-dom';

// --- Material Ui Imports --- //
import { makeStyles } from '@material-ui/core/styles';

import { Typography, CircularProgress, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  loadingText: {
    marginTop: theme.spacing(3),
    color: theme.palette.black,
    padding: 20,
    fontWeight: 300,
  },
}));

function AadCallback(props) {
  const classes = useStyles();
  // --- Hooks --- //
  const history = useHistory();
  const [authError, setAuthError] = useState(false);
  const { isAuthenticated, checkForSavedAuthentication } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      checkForSavedAuthentication().then((response) => {
        if (!response) {
          setAuthError(true);
        } else {
          try {
            let localUrl = getStoredPath();
            clearStoredPath();
            history.push(localUrl);
          } catch {
            setAuthError(true);
          }
        }
      });
    }
  }, []);

  return authError ? (
    <div style={{ flexDirection: 'column', textAlign: 'center', flex: 1 }}>
      <Typography className={classes.loadingText} variant="h3">
        There was an error logging you in.
      </Typography>
    </div>
  ) : (
    <div style={{ flexDirection: 'column', textAlign: 'center', flex: 1 }}>
      <Typography className={classes.loadingText} variant="h3">
        Loading ...
      </Typography>
      <CircularProgress size={50} />
    </div>
  );
}

export default AadCallback;
