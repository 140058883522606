import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';

//Material UI
import {
  Box,
  Grid,
  CircularProgress,
  Button,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

//Components
import { TextMaskCustom } from 'components';

//Themes
import palette from 'themes/palette';

//Formik
import { TextField } from 'formik-material-ui';
import { Formik, Form, useFormikContext } from 'formik';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginBottom: '15px',
    fontWeight: '500',
    fontSize: '120%',
  },
}));

const BankTransfer = (props) => {
  const classes = useStyles();
  var prefix = props.prefix ? `${props.prefix}.` : '';

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Box className={classes.subtitle}>Bank Transfer Details:</Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <TextField
            name={prefix + 'nameOnAccount'}
            label="Name on Account"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            name={prefix + 'accountType'}
            label="Account Type"
            fullWidth
            variant="outlined"
            select
          >
            <MenuItem value="CK">Checking</MenuItem>
            <MenuItem value="SAV">Savings</MenuItem>
          </TextField>
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            name={prefix + 'routingNumber'}
            label="Routing Number"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            name={prefix + 'accountNumber'}
            label="Account Number"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          {/* <TextField
            name={prefix + 'paymentDate'}
            label="Payment Date"
            fullWidth
            variant="outlined"
          /> */}
          <TextMaskCustom
            name={prefix + 'effectiveDate'}
            label="Payment Date"
            fullWidth
            variant="outlined"
            maskType="date"
            unmask={false}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BankTransfer;
