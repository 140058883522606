import React, { useState, Fragment, useEffect } from 'react';
import clsx from 'clsx';
import * as yup from 'yup';
import 'assets/scss/branches.scss';

//Material UI
import {
  Box,
  ButtonBase,
  CircularProgress,
  Link,
  TextField as MuiTextField,
  MenuItem,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import palette from 'themes/palette';

//Icon
import LocationOnIcon from '@material-ui/icons/LocationOn';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'store';

//Services
import { useAppSettingsService } from 'services/AppSettingsService';
import { useAuth } from 'services/AuthService';

// --- Styles --- //
const useStyles = makeStyles((theme) => ({
  //BranchView Styles
  root: {
    backgroundColor: theme.palette.background.default,
  },
  container: {
    margin: '30px',
  },
  title: {
    color: palette.highway.main,
  },
  instructions: {
    marginTop: '10px',
    fontWeight: 300,
  },
  //BranchBox Styles
  button: {
    width: '100%',
  },
  boxContainer: {
    width: '100%',
    border: '1px solid #dddddd',
    borderRadius: '15px',
    backgroundColor: 'white',
    overflow: 'hidden',
    // Hover styles can be found in branches.scss
  },
  name: {
    fontWeight: '600',
    color: 'white',
    fontSize: '22px',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
    padding: '15px',
    backgroundColor: palette.forest.main,
  },
  addressBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
  },
  address: {
    fontWeight: 300,
    fontFamily: 'Roboto',
    textAlign: 'left',
    fontSize: '16px',
  },
}));

const BranchBox = (props) => {
  const classes = useStyles();

  const { branch, ...rest } = props;
  return (
    <div className={clsx('boxContainer', classes.boxContainer)}>
      <Box className={clsx('name', classes.name)}> {branch.name}</Box>
      <Box className={classes.addressBox}>
        <LocationOnIcon style={{ marginRight: '10px' }} color="secondary" />
        <Box className={classes.address}>
          {branch.addressLine1}
          <br />
          {branch.city}, {branch.state} {branch.postalCode}
        </Box>
      </Box>
    </div>
  );
};

const BranchView = (props) => {
  const classes = useStyles();
  const { history } = props;

  //State Hooks
  const [loading, setLoading] = useState(true);
  const [branchSelected, setBranchSelected] = useState(false);

  //Services
  const appSettingsService = useAppSettingsService();
  const { user, updateAppSettings, appSettings } = useAuth();

  //Redux Hooks
  const dispatch = useDispatch();

  //Get branches from redux
  const branches = useSelector((state) => state.reducer.branches);

  //Load branches
  useEffect(() => {
    if (branches.length !== 0) {
      setLoading(false);
    }
  }, [branches]);

  //Check to see if defaults already set or not
  useEffect(() => {
    if (
      appSettings.defaultBranch &&
      appSettings.defaultBranchName &&
      branchSelected
    ) {
      //Redirect to calendar page
      history.push({
        pathname: '/calendar',
      });
      dispatch(actions.setLoggedIn(true));
    }
  }, [appSettings]);

  const branchOnClick = (branch) => {
    //Call API to save default branch
    const branchIdSettings = {
      application: process.env.REACT_APP_SETTINGS_NAME,
      username: user.mail ? user.mail : user.userPrincipalName,
      key: 'defaultBranch',
      value: branch.coreId.toString(),
    };

    const branchNameSettings = {
      application: process.env.REACT_APP_SETTINGS_NAME,
      username: user.mail ? user.mail : user.userPrincipalName,
      key: 'defaultBranchName',
      value: branch.name,
    };

    appSettingsService.setAppSettings(branchIdSettings).then((response) => {
      if (response.status === 200) {
        appSettingsService
          .setAppSettings(branchNameSettings)
          .then((response1) => {
            if (response1.status === 200) {
              updateAppSettings({
                [response.data.key]: response.data.value,
                [response1.data.key]: response1.data.value,
              });

              setBranchSelected(true);

              //Set selected branch in redux
              dispatch(
                actions.setSelectedBranch({
                  name: branch.name,
                  id: branch.coreId,
                })
              );

              //Redirect to calendar page
              history.push({
                pathname: '/glonly',
              });
            }
          });
      }
    });
  };

  return (
    <div className={classes.root}>
      {loading === true ? (
        //Show spinner
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '30px',
          }}
        >
          <CircularProgress
            style={{ marginBottom: '20px' }}
            color="secondary"
          />
          <Box style={{ fontWeight: 300, fontSize: '130%' }}>Loading...</Box>
        </Box>
      ) : (
        <Box className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box className={clsx('pageTitle', classes.title)}>
                Select a Default Branch
              </Box>
              <Box className={classes.instructions}>
                Click on a branch below to set it as your default branch.{' '}
              </Box>
            </Grid>
            {branches.map((branch, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <ButtonBase
                    focusRipple
                    className={classes.button}
                    onClick={() => branchOnClick(branch)}
                  >
                    <BranchBox branch={branch} />
                  </ButtonBase>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default BranchView;
