// --- Imports --- //
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const CatalogService = class {
  constructor(recaptchaService) {
    this.client = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_PAYMENT_API_VERSION}/catalog`,
      validateStatus: function () {
        return true;
      },
      headers: { 'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_API_SUB_KEY}` }
    });
    const hmm = process.env.REACT_APP_API_SUB_KEY;
    this.recaptchaService = recaptchaService || undefined;
  }

  ///////////////////////////

  async getStates() {
    const token = await this.recaptchaService();
    try {
      const { data: response, status } = await this.client.get('/state', {
        params: {},
        headers: { token: token},
      });
      return response;
    } catch (error) {
      return {};
    }
  }

  async getBranches() {
    const token = await this.recaptchaService();
    try {
      const { data: response, status } = await this.client.get('/branch', {
        params: {},
        headers: { token: token},
      });
      // console.log(response);
      return response;
    } catch (error) {
      return {};
    }
  }

  async getPaymentTypes() {
    const token = await this.recaptchaService();
    try {
      const { data: response, status } = await this.client.get(
        '/paymenttypes',
        {
          params: {},
          headers: { token: token},
        }
      );
      // console.log(response);
      return response;
    } catch (error) {
      return {};
    }
  }

  /////////////////////
};

export const useCatalogService = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  return new CatalogService(executeRecaptcha);
};

export default new CatalogService();
