import { BaseClass } from 'utilities';

const AddressModel = class extends BaseClass {
  constructor(props = {}) {
    super(props);
    this.lineOne = props.lineOne || '';
    this.lineTwo = props.lineTwo || '';
    this.city = props.city || '';
    this.state = props.state || 'OR';
    this.zip = props.zip || '';
  }
};

export default AddressModel;
