import React, { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';

import palette from 'themes/palette';

// --- Material --- //
import {
  Typography,
  Box,
  makeStyles,
  CircularProgress,
  Button,
  Chip,
} from '@material-ui/core';

//Icons
import CachedIcon from '@material-ui/icons/Cached';

// --- Services --- //
import { useAuth } from 'services/AuthService';
import { usePaymentService } from 'services/PaymentService';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '10px',
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: '0px 4px',
    },
  },
  content: {
    marginTop: theme.spacing(2),
  },
  title: {
    // marginBottom: '20px',
    fontWeight: 700,
    fontSize: '35px',
    color: palette.highway.dark,
    [theme.breakpoints.down('xs')]: {
      padding: '15px 0 0 0',
      fontSize: '25px',
    },
  },
  titleBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  lastRefreshed: {
    fontSize: '90%',
    fontStyle: 'italic',
    paddingTop: '10px',
  },
}));

const CompletedPaymentsView = (props) => {
  const classes = useStyles();

  //Services
  const { checkingLogin, isAuthenticated, handleLogin } = useAuth();

  const paymentService = usePaymentService();

  //State Hooks
  const [lastRefreshed, setLastRefreshed] = useState(moment().format());
  const [loading, setLoading] = useState(true);
  const [completedPayments, setCompletedPayments] = useState([]);

  const [loginOpen, setLoginOpen] = useState(false);

  useEffect(() => {
    if (!isAuthenticated && !loginOpen) {
      setLoginOpen(true);
      handleLogin();
    }
  });

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      paymentService.getCompletedPayments().then((response) => {
        setCompletedPayments(response.data);
        setLoading(false);
      });
    }
  }, [isAuthenticated, lastRefreshed]);

  const refreshClick = () => {
    setLastRefreshed(moment().format());
  };

  const handleDataMapping = () => {
    return completedPayments.map((payment) => {
      try {
        return {
          paymentDate: moment(payment.paymentDate)
            .subtract(new Date().getTimezoneOffset(), 'minutes')
            .format('MM/DD/YYYY, hh:mm A'),
          loggedInUser: payment.loggedInUser,
          accountNumber: payment.accountNumber,
          amount: payment.amount,
          feeAmount: payment.feeAmount,
          loanPaymentAmount: payment.loanPaymentAmount,
          fundingType: payment.fundingType,
          glOnlyFlag: payment.glOnlyFlag,
          memberNumber: payment.memberNumber,
          overrideBillingAddress: payment.overrideBillingAddress,
          overrideDebitCardCheck: payment.overrideDebitCardCheck,
          overrideMaxAmount: payment.overrideMaxAmount,
          overridePersonNumber: payment.overridePersonNumber !== '',
          overrideAccountNumber: payment.overrideAccountNumber,
          overrides: {
            debitCard: payment.overrideDebitCardCheck,
            maxAmount: payment.overrideMaxAmount,
            billingAddress: payment.overrideBillingAddress,
            dna: payment.overridePersonNumber !== '',
            accountNumber: payment.overrideAccountNumber,
          },
        };
      } catch (error) {
        return;
      }
    });
  };

  const columns = [
    {
      name: 'paymentDate',
      label: 'Payment Date',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'loggedInUser',
      label: 'Employee',
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'memberNumber',
      label: 'Member #',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'accountNumber',
      label: 'Account #',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'feeAmount',
      label: 'Fee Amount',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'loanPaymentAmount',
      label: 'Loan Amount',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'amount',
      label: 'Total Amount',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'fundingType',
      label: 'Funding Type',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value === 'card')
            return (
              <Chip
                label={'Debit Card'}
                style={{
                  backgroundColor: palette.river.main,
                  color: 'white',
                }}
                size="small"
              />
            );
          if (value === 'ach')
            return (
              <Chip
                label={'Bank Transfer'}
                style={{
                  backgroundColor: palette.forest.main,
                  color: 'white',
                }}
                size="small"
              />
            );
        },
      },
    },
    {
      name: 'glOnlyFlag',
      label: 'GL Only',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value === true ? (
            <Chip
              label={'GL Only'}
              style={{
                backgroundColor: palette.highway.main,
                color: 'white',
              }}
              size="small"
            />
          ) : (
            ''
          );
        },
      },
    },

    {
      name: 'overrideDebitCardCheck',
      label: 'Override Debit Card?',
      options: {
        filter: true,
        sort: true,
        display: 'excluded',
      },
    },
    {
      name: 'overrideAccountNumber',
      label: 'Override Account Number?',
      options: {
        filter: true,
        sort: true,
        display: 'excluded',
      },
    },
    {
      name: 'overrideMaxAmount',
      label: 'Override Max Amount?',
      options: {
        filter: true,
        sort: true,
        display: 'excluded',
      },
    },
    {
      name: 'overrideBillingAddress',
      label: 'Override Billing Address?',
      options: {
        filter: true,
        sort: true,
        display: 'excluded',
      },
    },
    {
      name: 'overridePersonNumber',
      label: 'Override DNA?',
      options: {
        filter: true,
        sort: true,
        display: 'excluded',
      },
    },
    {
      name: 'overrides',
      label: 'Overrides',
      options: {
        filter: false,
        download: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fragment>
              {value.dna === true ? (
                <Chip
                  label={'DNA'}
                  style={{
                    backgroundColor: palette.brick.main,
                    color: 'white',
                    margin: '2px',
                  }}
                  size="small"
                />
              ) : (
                ''
              )}
              {value.debitCard === true ? (
                <Chip
                  label={'Debit Card'}
                  style={{
                    backgroundColor: palette.mountain.main,
                    color: 'white',
                    margin: '2px',
                  }}
                  size="small"
                />
              ) : (
                ''
              )}
              {value.maxAmount === true ? (
                <Chip
                  label={'Max Amount'}
                  style={{
                    backgroundColor: palette.beach.main,
                    color: 'white',
                    margin: '2px',
                  }}
                  size="small"
                />
              ) : (
                ''
              )}

              {value.billingAddress === true ? (
                <Chip
                  label={'Billing Address'}
                  style={{
                    backgroundColor: palette.forest.main,
                    color: 'white',
                    margin: '2px',
                  }}
                  size="small"
                />
              ) : (
                ''
              )}

              {value.accountNumber === true ? (
                <Chip
                  label={'Account Number'}
                  style={{
                    backgroundColor: palette.highway.light,
                    color: 'white',
                    margin: '2px',
                  }}
                  size="small"
                />
              ) : (
                ''
              )}
            </Fragment>
          );
        },
      },
    },
  ];

  const options = {
    searchOpen: true,
    filterType: 'dropdown',
    selectableRows: false,
    rowsPerPage: 25,
    rowsPerPageOptions: [10, 25, 50, 100],
    responsive: 'simple',
    downloadOptions: {
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
  };

  return (
    <div className={classes.root}>
      <Box className={classes.titleBar}>
        <Box>
          <Box className={clsx('sentinel', classes.title)}>
            Employee Completed Payments
          </Box>
          <Box className={classes.lastRefreshed}>
            Last Refreshed: {moment(lastRefreshed).format('MMMM DD, h:mm:ss a')}
          </Box>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={refreshClick}
          >
            <CachedIcon style={{ marginRight: '5px' }} />
            Refresh
          </Button>
        </Box>
      </Box>

      <div className={classes.content}>
        {completedPayments.length === 0 && loading ? (
          <Box style={{ textAlign: 'center' }}>
            <CircularProgress size={50} />
          </Box>
        ) : (
          <MUIDataTable
            title={'Completed Payments'}
            data={handleDataMapping()}
            columns={columns}
            options={options}
          />
        )}
      </div>
    </div>
  );
};

export default CompletedPaymentsView;
