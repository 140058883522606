// --- Imports --- //
import React from 'react';
// import PropTypes from 'prop-types';

// --- Material Ui --- //
import { makeStyles } from '@material-ui/core/styles';

// --- Styles --- //
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingTop: 16,
    color: theme.palette.primary.contrastText,
    flexShrink: 0
  },
  trees: {
    background: 'url(/images/trees-bg.png) repeat-x center top',
    height: '17.8124rem',
    '-webkit-background-size': 'auto auto',
    backgroundSize: 'auto',
  },
  content: {
    backgroundColor: '#597F54',
    // marginTop: -8,
    padding: theme.spacing(2),
  },
}));

// --- Component --- //
const TreeFooter = (props) => {
  const { children, ...rest } = props;
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <div className={classes.trees} />
      <div className={classes.content}>{children}</div>
    </footer>
  );
};

// --- Exports --- //
export default TreeFooter;

// footer {
//   color: #f6f6f6;
//   position: relative;
//   padding-top: 1rem; }

// footer div.trees {
//   background: url(./imgs/trees-bg.png) repeat-x center top;
//   height: 17.8125rem;
//   -webkit-background-size: auto auto;
//           background-size: auto; }

// footer div.wrapper {
//   padding-top: 3.125rem;
//   padding-bottom: 3.125rem;
//   background-color: #597F54; }

// footer a {
//   color: #f6f6f6; }
