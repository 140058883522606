// export const phoneNumber = [/\d/,/\d/,/\d/,' ',/\d/,/\d/,/\d/,' ',/\d/,/\d/,/\d/,/\d/];
// prettier-ignore
const digitRegex = /\d/;

// prettier-ignore
export const date =  [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
export const expirationDate = [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
// prettier-ignore
export const phoneNumber = [ '(', /[1-9]/,/\d/,/\d/,')',' ',/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/,];
// prettier-ignore
export const creditCard = [/\d/,/\d/,/\d/,/\d/,' ',/\d/,/\d/,/\d/,/\d/,' ',/\d/,/\d/,/\d/,/\d/,' ',/\d/,/\d/,/\d/,/\d/]
// prettier-ignore
export const debitCard = [/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/]

// prettier-ignore
export const americanExpress = [ /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
export const cvv = new Array(3).fill(digitRegex);
export const accountNumber = new Array(16).fill(digitRegex);
