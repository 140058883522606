import * as types from './Types';

export const setAllBranches = (data) => {
  return {
    type: types.SET_ALL_BRANCHES,
    data: data,
  };
};

export const setSelectedBranch = (data) => {
  return {
    type: types.SET_SELECTED_BRANCH,
    data: data,
  };
};

export const setLoggedIn = (data) => {
  return {
    type: types.SET_LOGGED_IN,
    data: data,
  };
};
