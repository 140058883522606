// --- Class Imports --- //
import Config from './RouteConfig';

// --- Layout Imports --- //
import { default as MainLayout } from '../layouts/Main';

// --- View Imports --- //
import { default as PaymentFormView } from 'views/PaymentFormView';
import { default as SuccessView } from 'views/SuccessView';
import { default as BranchView } from 'views/BranchView';
import { default as LandingView } from 'views/Landing';
import { default as LogOutView } from 'views/LogOut';
import {
  default as CompletedPaymentsView,
  PublicCompletedPaymentsView,
} from 'views/CompletedPaymentsView';
import { default as AadCallbackView } from 'components/AadCallback';

//import { default as AadCallbackView } from 'components/AadCallback';

/**
 * Authenticated Redirect
 */
//const AuthConfig = new Config({
//  component: AadCallbackView,
//  layout: MainLayout,
//  paths: ['/authenticated'],
//  title: 'Authenticated',
//  icon: ApplicationsIcon,
//  href: '/authenticated'
//});

//Landing link and route config
const LandingConfig = new Config({
  component: LandingView,
  layout: MainLayout,
  paths: ['/', '/landing'],
  title: 'Landing',
  href: '/',
});

//Landing link and route config

const LogOutConfig = new Config({
  component: LogOutView,
  layout: MainLayout,
  paths: ['/logout'],
  title: 'Landing',
  href: '/logout',
});

//Landing link and route config

const BranchesConfig = new Config({
  component: BranchView,
  layout: MainLayout,
  paths: ['/branches'],
  title: 'Branches',
  href: '/branches',
  isProtected: true,
});

const PaymentConfig = new Config({
  component: PaymentFormView,
  layout: MainLayout,
  paths: ['/payment'],
  title: 'Payment',
  href: '/payment',
  isProtected: false,
});

const GLOnlyPaymentConfig = new Config({
  component: PaymentFormView,
  layout: MainLayout,
  paths: ['/glonly'],
  title: 'GL Payment',
  href: '/glonly',
  isProtected: false,
});

const SuccessConfig = new Config({
  component: SuccessView,
  layout: MainLayout,
  paths: ['/success'],
  title: 'Success',
  href: '/success',
  isProtected: true,
});

const CompletedPaymentsConfig = new Config({
  component: CompletedPaymentsView,
  layout: MainLayout,
  paths: ['/completedpayments'],
  title: 'Completed Payments',
  href: '/completedpayments',
  isProtected: false,
});

const PublicCompletedPaymentsConfig = new Config({
  component: PublicCompletedPaymentsView,
  layout: MainLayout,
  paths: ['/publicpayments'],
  title: 'Public Completed Payments',
  href: '/publicpayments',
  isProtected: false,
});

/**
 * Authenticated Redirect
 */
const AuthConfig = new Config({
  component: AadCallbackView,
  layout: MainLayout,
  paths: ['/authenticated'],
  title: 'Authenticated',
  href: '/authenticated',
  isProtected: false,
});

export const publicRoutes = [LogOutConfig, LandingConfig, AuthConfig];
export const protectedRoutes = [
  BranchesConfig,
  PaymentConfig,
  SuccessConfig,
  GLOnlyPaymentConfig,
  CompletedPaymentsConfig,
  PublicCompletedPaymentsConfig,
];

export default [...publicRoutes, ...protectedRoutes];
