// --- Yup/Validation Imports --- //
import * as yup from 'yup';
// import 'utilities/validation';
import moment from 'moment';
import { alpha_num_limited, number } from 'utilities/validation/regex';

const BankTransferSchema = yup.object().shape({
  nameOnAccount: yup
    .string()
    .label('Name on Account')
    .matches(alpha_num_limited.regex, alpha_num_limited.message)
    .required(),
  routingNumber: yup
    .string()
    .label('Routing Number')
    .matches(number.regex, number.message)
    .max(16, 'Routing Max Length is 16')
    .required(),
  accountNumber: yup
    .string()
    .label('Account Number')
    .matches(number.regex, number.message)
    .max(17)
    .required(),
  accountType: yup.string().label('Account Type').required(),
  effectiveDate: yup
    .date()
    .min(moment().format('MM/DD/YYYY'), "Payment must be today's date or later")
    .label('Payment Date')
    .required(),
});

export default BankTransferSchema;
