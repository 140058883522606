import React, { Fragment } from 'react';

import palette from 'themes/palette';

// --- Material --- //
import {
  Typography,
  Box,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';

// --- Services --- //
import { useAuth } from 'services/AuthService';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    flexDirection: 'column',
  },
}));

const Landing = (props) => {
  const classes = useStyles();
  const { checkingLogin } = useAuth();

  return (
    <div className={classes.root}>
      <Box
        style={{
          textAlign: 'center',
        }}
      >
        {checkingLogin ? (
          <CircularProgress style={{ marginLeft: '20px' }} color="secondary" />
        ) : (
          <Fragment>
            <Box className="pageTitle" style={{ color: palette.forest.main }}>
              NWCU Payment Portal
            </Box>
            <Box
              style={{
                marginTop: '15px',
                color: palette.highway.main,
                fontWeight: 500,
              }}
            >
              Please Log In.
            </Box>
          </Fragment>
        )}
      </Box>
    </div>
  );
};

export default Landing;
