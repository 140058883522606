// -- Imports --- //
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';

// --- State Imports --- //
import { default as reducer } from './Reducers';

import * as actions from './Actions';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// --- Configuration Store
const store = createStore(
  combineReducers({
    reducer,
  }),
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

export { actions };

// --- Export Store --- //
export default store;
