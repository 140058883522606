import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import * as yup from 'yup';
import palette from 'themes/palette';

//Material UI
import { makeStyles } from '@material-ui/styles';
import { Box, Button } from '@material-ui/core';

//Icons
import ErrorIcon from '@material-ui/icons/Error';

//Components
import { Dialog, CustomPopUp } from 'components';

//Formik
import { TextField } from 'formik-material-ui';
import { useFormikContext } from 'formik';

const OverrideDialog = (props) => {
  const [isOverridden, setisOverridden] = useState(false);
  const { values, submitForm, resetForm, setFieldValue } = useFormikContext();
  const { overrideDialog, setOverrideDialog, errorMessage } = props;
  return (
    <CustomPopUp
      open={overrideDialog}
      title={
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            color: palette.brick.main,
          }}
        >
          {' '}
          <ErrorIcon
            style={{ marginRight: '10px', color: palette.brick.main }}
            fontSize="large"
          />
          Error
        </Box>
      }
      disableClose
      onClose={() => {
        window.location.reload();
        //setOverrideDialog(false);
        //resetForm();
      }}
      actions={
        <Fragment>
          <Button
            onClick={() => {
              window.location.reload();
              //setOverrideDialog(false);
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
          <Button
            name="confirmOverride"
            onClick={() => { setFieldValue("isOverridden", true); submitForm(); }}
            variant="contained"
            color="primary"
            type="submit"
          >
            Override
          </Button>
        </Fragment>
      }
    >
      <Box style={{ fontWeight: 300, fontSize: '20px' }}>
        {errorMessage ? errorMessage : 'Looks like something went wrong.'}
        <Box style={{ marginTop: '20px', marginBottom: '20px' }}>
          Would you like to override and complete this payment?
        </Box>
      </Box>
    </CustomPopUp>
  );
};

export default OverrideDialog;
