import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/scss/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { TreeFooter } from './layouts/Main/components';
import { Box } from '@material-ui/core';

ReactDOM.render(
  <React.StrictMode>
    <div className="content">
      <App />
    </div>
    <TreeFooter>
      <Box style={{ marginLeft: '15px' }}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src="/images/ncua-logo-white.png"
            alt="NCUA Logo"
            style={{ marginRight: '10px' }}
          />
          <small>
            <a
              href="https://www.nwcu.com/storage/app/media/Disclosure%20PDFs/ncua-insured-funding.pdf"
              style={{ fontWeight: 300, color: '#eeeeee' }}
              target="_blank"
            >
              Federally insured by NCUA.
            </a>
          </small>
        </Box>

        <Box
          style={{
            fontWeight: 300,
            color: '#eeeeee',
            marginTop: '5px',
            marginBottom: '10px',
          }}
        >
          <small>
            This site is protected by reCAPTCHA and the Google{' '}
            <a
              style={{
                color: 'white',
                fontWeight: 500,
              }}
              href="https://policies.google.com/privacy"
              target="_blank"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              style={{
                color: 'white',
                fontWeight: 500,
              }}
              href="https://policies.google.com/terms"
              target="_blank"
            >
              Terms of Service
            </a>{' '}
            apply.
          </small>
        </Box>
      </Box>
    </TreeFooter>{' '}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
