import React, { useState, useEffect } from 'react';
//Masks
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';

//Formik
import { useField } from 'formik';
//Material UI
import { TextField, Button, InputAdornment } from '@material-ui/core';

//Icons
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 4, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const CurrencyInput = (props) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...props.maskOptions,
  });

  const { name, unmask, maskOptions, ...rest } = props;

  // -- Hooks ---//
  const [field, meta, helpers] = useField(name);
  const [displayValue, setDisplayValue] = useState('');

  const showError = meta.error && meta.touched;

  //On every character typed, we set the newValue.
  //We unmask it only if that field is passed in.
  const handleChange = (event) => {
    const newValue = event.target.value;
    if (unmask) {
      helpers.setValue(unmaskValue(newValue));
    } else {
      helpers.setValue(newValue);
    }
    setDisplayValue(newValue);
  };

  // This makes it so it doesnt show an error until the box has been left
  const handleBlur = () => {
    helpers.setTouched(true);
  };

  //   return <MaskedInput mask={currencyMask} {...inputProps} />;
  return (
    <TextField
      error={showError}
      value={displayValue}
      name={name}
      type="text"
      helperText={showError ? meta.error : ''}
      onChange={handleChange}
      onBlur={(event) => {
        handleChange(event);
        handleBlur();
      }}
      InputProps={{
        inputComponent: CustomMaskInput,
        inputProps: {
          mask: currencyMask,
          guide: false,
        },
        startAdornment: (
          <InputAdornment position="start">
            <AttachMoneyIcon />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

//  Unmask value given masking char
export const unmaskValue = (value, mask = /\D/g, replace = '') => {
  if (typeof value !== 'string') {
    value = value.toString();
  }
  return value.replace(mask, replace);
};

// Custom Mask Input wrapper component
const CustomMaskInput = (props) => {
  const { inputRef, ...rest } = props;

  return (
    <MaskedInput
      ref={(ref) => (ref ? inputRef(ref.inputElement) : null)}
      {...rest}
    />
  );
};

CurrencyInput.defaultProps = {
  inputMode: 'numeric',
  maskOptions: {},
  unmask: false,
};

export default CurrencyInput;
