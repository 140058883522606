// --- Imports --- //
import * as types from './Types';

const initialState = {
  selectedBranch: {
    id: '10',
    name: 'Member Connect',
  },
  branches: [],
  loggedIn: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ALL_BRANCHES:
      return { ...state, branches: action.data };
    case types.SET_SELECTED_BRANCH:
      return { ...state, selectedBranch: action.data };
    case types.SET_LOGGED_IN:
      return { ...state, loggedIn: action.data };
    default:
      return state;
  }
};

export default reducer;
